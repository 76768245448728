import { Page } from "@react-pdf/renderer";
import { GeneratePSVFormV4ReturnObject } from "../..";
import AdditionalNotes from "./Content/AdditionalNotes";
import CalculationBasisNAssumptions from "./Content/CalculationBasisNAssumptions";
import Conclusion from "./Content/Conclusion";
import ControlValveData from "./Content/ControlValveData";
import EquipmentData from "./Content/EquipmentData";
import FluidComposition from "./Content/FluidComposition";
import { ObjectiveSection, /* objectiveSection */ } from "./Content/Objective";
import PSVData from "./Content/PSVData";
import Recommendations from "./Content/Recommendations";
import References from "./Content/References";
import Results from "./Content/Results";
import Scenarios from "./Content/Scenarios";
import { ContentPageHeader, /* contentPageHeader */ } from "./core/PageTemplate/ContentPage";

export const contentPageItems = (a:GeneratePSVFormV4ReturnObject, orifice_designations:any, scenarios:any) => {

  return (
    <Page size="A4" style={{width:'100%'}} wrap>
      <ContentPageHeader data={a} />
      <ObjectiveSection {...a} />
      <PSVData {...a} />
      <Scenarios data={a} scenarios={scenarios} />
      <CalculationBasisNAssumptions {...a} />
      <FluidComposition data={a} scenarios={scenarios} />
      <ControlValveData {...a} />
      <EquipmentData {...a} />
      <Results {...a} />
      <Conclusion data={a} orifice_designations={orifice_designations} />
      <Recommendations {...a} />
      <References {...a} />
      <AdditionalNotes {...a} />
    </Page>
  )
}