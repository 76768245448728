import React, {useState, useEffect} from 'react'
import { POSTRequest } from '../../../../../../components/apiHandler'
import { AugTable } from '../../../../../../components/Table'
import { TablePropsColInterface } from '../../../../../../components/Table/interface'

import {Row,Col} from "react-bootstrap"

import ContentFrame from '../../../../../../Frame/ContentFrame'
import { SelectNoAddContextless } from '../../../../../../components/Form'
import { SelectObject } from '../../../../../../interfaces'

const FlangeRating = () => {
  const [tableSet,setTableSet] = useState<{cols: TablePropsColInterface[], materials : SelectObject[]}>({cols: [], materials: []})  
  const [chosenMaterial, setChosenMaterial] = useState<SelectObject>({value: 0, label: ''});

  useEffect(() => {
    
    POSTRequest("standards/asme/flange_ratings.php?action=PRE_TABLE_RENDER",'')
      .then(response => setTableSet({
        cols :[
        { field: "af_temperature", headerText: "Temperature" },
        ...response.classes.map((item:{id:string, class:string}) => ({field : `af_${item.class}`, headerText:`${item.class}#`,id:item.id}))
        ],
        materials : response.materials
      }))
  },[])

  return (
    <ContentFrame title="ANSI Flange Rating">
      <Row>
        {/* <Col xs={12}>
          <Button onClick={()=>POSTRequest("standards/asme/flange_ratings.php?action=_TEST",'')}>Make Flanges</Button>
        </Col> */}
        <Col xs={12}>
          <SelectNoAddContextless valueAsState={setChosenMaterial} name="materials" options={tableSet.materials} />
        </Col>
        <Col xs={12}>
          <AugTable
            query={[
              {param : 'bm_id', value : chosenMaterial.value.toString()},
              ...tableSet.cols.map(item => ({param: item.field, value : item.id? item.id.toString() : ''}))
            ]}
            allowResizing
            allowReordering
            allowEditing
            editOptions={{
              allowEditing: true, 
              allowAdding: true,
              showConfirmDialog: true,
              showDeleteConfirmDialog: true,
              allowDeleting: true,
              mode: "Dialog",
            }}
            columns={tableSet.cols}
            dataManagerUrl={{
              url: "standards/asme/flange_ratings.php?action=INIT",
              insertUrl: "standards/asme/flange_ratings.php?action=ADD",
              removeUrl: "standards/asme/flange_ratings.php?action=REMOVE",
              updateUrl: "standards/asme/flange_ratings.php?action=UPDATE",
            }}
        />
        </Col>
      </Row>
    </ContentFrame>
  )
}

export default FlangeRating