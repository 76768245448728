import React from 'react'
import { GeneratePSVFormV4ReturnObject } from '../../..'
import ContentTemplate from '../core/PageTemplate/ContentTemplate'
import ReactParser from "../core/PageParser/ReactParser";
import { View } from '@react-pdf/renderer';
import { _thickBorder } from '../core/items';
import { resultsSuite } from '../../../../../calculation/v3';

const Results = (data: GeneratePSVFormV4ReturnObject) => {
  const tableStyles = {
  };

  const prepareResults = () => {
    return data.op.filter(op => op.identified === true).map((op, index, opArr) => {
      const resultCalculation = resultsSuite(data, op)
      return <div className={`headerRow ${index < opArr.length - 1 ? 'borderBottom' : ''}`}>
        <div className="width-120 text-9 borderRight">{op.scenario.label}</div>
        <div className="width-160 text-8 headerRow borderRight">
          <div className="width-40 borderRight">{resultCalculation.pressure.accumulation.barg}</div>
          <div className="width-40 borderRight">{resultCalculation.pressure.accumulation.psig}</div>
          <div className="width-40 borderRight">{resultCalculation.temperature.c}</div>
          <div className="width-40 borderRight">{resultCalculation.temperature.f}</div>
        </div>
        <div className="width-220 headerRow text-8">   
          <div className="width-44 borderRight">{resultCalculation.flow.mass.kgh}</div>       
          <div className="width-44 borderRight">{resultCalculation.flow.mass.lbh}</div>       
          <div className="width-44 borderRight">{resultCalculation.flow.molar.mmscfd}</div>       
          <div className="width-44 borderRight">-</div>       
          <div className="width-44 borderRight">-</div>       
        </div>
        <div className="width-80 headerRow">
          <div className="width-40 borderRight">{resultCalculation.area.mm2}</div>
          <div className="width-40 borderRight">{resultCalculation.area.in2}</div>
        </div>
      </div>
    })
  }


  return <ContentTemplate title="Results" index={8}>    
  <View style={{ width: "100vw", textAlign: "center" }}>
    <ReactParser stylesheet={tableStyles}>
      <div
        style={{
          width: "98vw",
          textAlign: "center",
          margin: "5px",
          display: "flex",
        }}
      >
        <div style={{border: _thickBorder,display:'flex',flexDirection: "column" }}>
          <div className="headerRow fw-bold background-grey borderBottom">
            <div className="width-120 borderRight" style={{display:'flex',justifyContent: 'center', alignItems: 'center'}}>
              <div>Scenario</div>
            </div>
            <div className="headerCol">              
              <div className="headerRow">
                <div className="headerCol width-160 borderBottom">
                  <div className="borderBottom borderRight">Relief Conditions</div>
                  <div className="headerRow  pb-0">
                    <div className="width-80 pb-0 borderRight">Pressure</div>                    
                    <div className="width-80 pb-0 borderRight">Temperature</div>                    
                  </div>
                </div> 
                <div className="borderBottom width-220 borderRight">Relief Rate</div>             
                <div className="borderBottom width-80 ">Required Relief Area</div>    
              </div>
              <div className="headerRow" style={{width:'460px', minWidth:'460px'}}>                         
                <div className="headerRow width-160 text-9">
                  <div className="width-40 borderRight ">barg</div>                    
                  <div className="width-40 borderRight ">psig</div>                    
                  <div className="width-40 borderRight ">°C</div>                    
                  <div className="width-40 borderRight">°F</div>                    
                </div>
                <div className="headerRow width-220 text-8">
                  <div className="width-44 borderRight ">kg/hr</div>                    
                  <div className="width-44 borderRight ">lb/hr</div>                    
                  <div className="width-44 borderRight ">MMSCFD</div>                    
                  <div className="width-44 borderRight ">m3/h</div>                    
                  <div className="width-44 borderRight ">USGPM</div>                    
                </div>
                <div className="headerRow text-9">  
                  <div className="width-40 borderRight pb-0">mm2</div>                     
                  <div className="width-40 borderRight pb-0">in2</div>                     
                </div>
              </div>
            </div>
          </div>
          {
            prepareResults()
          }
        </div>
      </div>
    </ReactParser>
  </View>
  </ContentTemplate>
}
export default Results