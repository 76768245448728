import React  from "react";
import {useForm, FormProvider} from "react-hook-form"
import useFormPersist from 'react-hook-form-persist'

import {Button} from "react-bootstrap";

import "./Form.css" ;
import { FormInterface, FormSubmitButtonInterface } from "./interfaces";

export const Form = <T=any,>(FormProps: FormInterface) => {
  const formID = `form-${new Date().getTime()}`;
  const methods = useForm<T>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: FormProps.defaultValues,
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined
  });

  const {  
      reset,
      handleSubmit,
  } = methods;


  return <>
    <FormProvider {...methods}>
      <form 
        onSubmit={
          handleSubmit((data:any) => FormProps.onSubmit(data).then(()=>{if(FormProps.resetOnSuccess) reset()}))
        }
        id={formID}
      >
        {FormProps.children}
      </form>
    </FormProvider>
  </>
}

export const FormPersist = <T=any,>(FormProps: FormInterface) => {
  const formID = `form-${new Date().getTime()}`;
  const methods = useForm<T>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: FormProps.defaultValues,
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined
  });

  const {  
      reset,
      handleSubmit,
      watch,
      setValue,
  } = methods;


    useFormPersist("synergy-psv-form-persist", {
      watch, 
      setValue : setValue,
      storage: window.localStorage, // default window.sessionStorage
      exclude: ['baz']
    });

  return <>
    <FormProvider {...methods}>
      <form 
        onSubmit={
          handleSubmit((data:any) => FormProps.onSubmit(data).then(()=>{if(FormProps.resetOnSuccess) reset()}))
        }
        id={formID}
      >
        {FormProps.children}
      </form>
    </FormProvider>
  </>
}

export const SubmitButton = (props: FormSubmitButtonInterface) => {
  return (<Button 
    className={`${props.className} px-4`} 
    variant={props.variant ? props.variant : "primary"} 
    type="submit"
  >
    {props.caption}
  </Button>)
}

export * from "./Inputs/Line";
export * from "./Inputs/Lines3/index";
export * from "./Inputs/Check";
export * from "./Inputs/Datepicker";
export * from "./Inputs/Select/";

