import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { DeepPartial } from 'react-hook-form'
import { Form, Line, SubmitButton } from '../../../components/Form'
import { showAlert } from '../../../components/js'

export interface CreateUserFormReturnObject {
  name:  string,
  initials : string,
  email : string,
  level : number,
  password1: string,
  password2: string
}

interface CreateUserFormProps {
  onSubmit : (a:CreateUserFormReturnObject) => Promise<any>
  defaultValues ?: DeepPartial<CreateUserFormReturnObject>
}

const CreateUserForm = (props: CreateUserFormProps) => {
  const handleSubmit = (a:CreateUserFormReturnObject) => 
  new Promise<any>((resolve, reject) => {
    if (a.password1 !== a.password2) {showAlert('Passwords Do Not Match!','warn'); reject(false)}
    else {
      resolve(props.onSubmit(a))
    }
  })
  return (    
    <Form onSubmit={handleSubmit} defaultValues={props.defaultValues}>
      <Row>
        <Col xs={12} sm={8}><Line required name="name" caption="Name" /></Col>
        <Col xs={12} sm={4}><Line required name="initials" maxLength={4} caption="Initials" /></Col>
        <Col xs={12} sm={6}><Line required name="email" caption="Email" /></Col>
        <Col xs={12} sm={6}><Line required name="level" caption="User Level (0 is admin, 1 is normal account)" type="number" /></Col>
        <Col xs={12} sm={6}><Line required name="password1" caption="Password" type="password" /></Col>
        <Col xs={12} sm={6}><Line required name="password2" caption="Confirm Password" type="password" /></Col>
      </Row>
      <Row>
        <Col xs={12}><SubmitButton caption="Add User" /></Col>
      </Row>
    </Form>
  )
}

export default CreateUserForm