import React from "react";
import { AugTable } from "../../../../../components/Table";
import ContentFrame from "../../../../../Frame/ContentFrame";

const StandardOrifice = () => {
  return (
  <ContentFrame title="API 526 Standard Orifices" >
    <AugTable
      allowEditing
      editOptions={{
        allowEditing: true,
        allowAdding: true,
        showConfirmDialog: true,
        showDeleteConfirmDialog: true,
        allowDeleting: true,
        mode: "Dialog",
      }}
      columns={[
        { field: "or_designation", headerText: "Designation" },
        { field: "or_eff_area", headerText: "Effective Area (mm2)" },
        { field: "or_eff_area_in", headerText: "Effective Area (in2)"},
      ]}
      dataManagerUrl={{
        url: "standards/orifice.php?action=INIT",
        insertUrl: "standards/orifice.php?action=ADD",
        removeUrl: "standards/orifice.php?action=REMOVE",
        updateUrl: "standards/orifice.php?action=UPDATE",
      }}
    />
  </ContentFrame>
  );
};

export default StandardOrifice;
