import React from 'react'
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../App'
import { POSTRequest } from '../../../components/apiHandler'


const Logout = () => {
  const navigate = useNavigate();
  const {setUser} = React.useContext(UserContext)

  POSTRequest("user.php","LOGOUT")
  .then(response => {
    if(response.status) {
      localStorage.removeItem("synergy-login")
      setUser(null)
      navigate("/")
    }
  })
  return (<div>Logging Out....</div>)
}

export default Logout