import React, {useState} from "react";
import { useFormContext, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import {
  FormAsyncSelectCreatableInterface,
  FormAsyncSelectInterface,
  FormSelectOptionsInterface,
} from "../../interfaces";

import Frame from "../../Components/Frame";
import { logger } from "../../../js";

export const AsyncSelectInput = (props: FormAsyncSelectInterface) => {
  const { control } = useFormContext();

  const defaultRequired =
    props.required !== undefined
      ? typeof props.required === "string"
        ? props.required
        : props.required === true
        ? "Please select a value for this field"
        : undefined
      : undefined;

  return (
    <Controller
      control={control}
      name={props.name}
      rules={{ required: defaultRequired }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <Frame {...props}>
          <AsyncSelect
            isClearable
            defaultOptions
            cacheOptions={props.cacheOptions ?? true}
            onChange={(c) => {
              logger.log(c);
              onChange(c);
              if (props.valueAsState) {
                props.valueAsState(c);
              }
            }}
            loadOptions={(a: string) => props.loadOptions([a, value?.label])}
            value={value}
          />
        </Frame>
      )}
    />
  );
};

export const AsyncSelectCreatable = (props: FormAsyncSelectCreatableInterface) => {
  const [options, setOptions] = useState<FormSelectOptionsInterface[]>([])

  const methods = useFormContext();
  const defaultRequired =
    props.required !== undefined
      ? typeof props.required === "string"
        ? props.required
        : props.required === true
        ? "Please select a value for this field"
        : undefined
      : undefined;

  return (
    <Controller
      control={methods.control}
      name={props.name}
      rules={{ required: defaultRequired }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <Frame {...props}>
          <AsyncCreatableSelect
            isClearable
            defaultOptions={options.length === 0 ? true : options}
            cacheOptions={props.cacheOptions ?? true}
            onChange={(c) => {
              onChange(c);
              if (props.valueAsState) {
                props.valueAsState(c);
              }
            }}
            loadOptions={(a: string) => props.loadOptions([a, value?.label])}
            value={value}
            onCreateOption={(value) => {
              props.onCreate(value)
              .then((x) => {
                onChange(x)
                setOptions((prev) => ([...prev, x]))
              });
            }}
          />
        </Frame>
      )}
    />
  );
};
