import { logger } from "../../../../components/js"

/* k Cp/Cv */
export const k = (Cp: number, Cv: number) => Cp/Cv

/* F32 (API 520) */
export const C = (k : number) => {
  const _k = Number(k)
  const kAdd1 = _k + 1
  const kMin1 = _k - 1
  const TwoOverkAdd1 = 2 / kAdd1
  const kAdd1OverkMin1 = kAdd1/kMin1
  logger.log(`C Constant : k : ${_k}, kAdd1 : ${kAdd1}, kMin1 : ${kMin1}, 2/(k+1) = ${TwoOverkAdd1}, (k+1)/(k-1) = ${kAdd1OverkMin1}, (2/k+1)^(k+1/k-1) = ${Math.pow(TwoOverkAdd1, kAdd1OverkMin1)}`)
  return 0.03948 * Math.sqrt(_k * Math.pow(TwoOverkAdd1, kAdd1OverkMin1))
}

/* Coefficient of Discharge */
export const Kd = (hasPRV:boolean) => hasPRV ? 0.975 : 0.62

/* Correction from Backpressure */
export const Kb = (isBalancedBellow : boolean, valueOrEstimate:number = 1.0) => isBalancedBellow ? valueOrEstimate : 1.0

/* Combination Correction Factor */
export const Kc = (hasRuptureDisk:boolean) => hasRuptureDisk ? 0.9 : 1.0

/* Constant F2 (Eq 18) */
export const F2 = (k:number, backpressure :number, relieving_pressure:number) => {
  const r = backpressure / relieving_pressure  
  return Math.sqrt((k / (k - 1)) * Math.pow(r, 2/k) *  ( (1 - Math.pow(r, (k-1/k))) / (1 - r) )    )
}