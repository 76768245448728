import React from 'react'
import { GeneratePSVFormV4ReturnObject } from '../../..'
import ReactParser from "../core/PageParser/ReactParser";
import { View } from '@react-pdf/renderer';
import ContentTemplate from '../core/PageTemplate/ContentTemplate'
import { conclusionSuite } from '../../../../../calculation/v3';
import StringParser from '../core/PageParser/StringParser';

const Conclusion = ({data, orifice_designations}:{data: GeneratePSVFormV4ReturnObject, orifice_designations:any[]}) => {
  
  const tableStyles = {
  };


  const processRows = () => {
    return data.op.filter(op => op.identified === true).map(op => {
      const values = conclusionSuite(orifice_designations,data,op)

      return <div className="headerRow">
      <div className="headerRow width-120 borderBottom borderRight">
        <div className="width-40 borderRight">{values.flow.mass.kgh}</div>
        <div className="width-40 borderRight text-8">{values.flow.molar.mmscfd}</div>
        <div className="width-40 "> - </div>
      </div>
      <div className="headerRow width-80 borderBottom borderRight">
        <div className="width-40 borderRight">{values.area.mm2}</div>
        <div className="width-40">{values.area.in2}</div>
      </div>
      <div className="headerCol width-50 borderBottom borderRight text-8">{values.orifice.calculated.designation}</div>            
      <div className="headerRow width-100 borderBottom borderRight">
        <div className="width-50 borderRight">{values.orifice.calculated.areaDesignation.mm2}</div>
        <div className="width-50">{values.orifice.calculated.areaDesignation.in2}</div>
      </div>            
      <div className="headerRow width-120 borderBottom borderRight">
        <div className="width-40 borderRight"></div>
        <div className="width-40 borderRight text-8"></div>
        <div className="width-40 "></div>
      </div>
      <div className="width-60 borderRight borderBottom">{values.orifice.chosen.chosenArea.mm2}</div>
      <div className="width-50  text-8 text-center borderBottom">
        {
          Number(values.orifice.chosen.chosenArea.mm2) > Number(values.orifice.calculated.areaDesignation.mm2) ? 
          <span style={{color:'green'}}>YES</span>:
          <span style={{color:'red'}}>NO</span>
        }
      </div>
    </div>
    })
  }

  return  <ContentTemplate title="Conclusions" index={9}>    
  <View style={{ width: "100vw", textAlign: "center" }}>
    <ReactParser stylesheet={tableStyles}>
      <div
        style={{
          width: "98vw",
          textAlign: "center",
          margin: "5px",
          display: "flex",
        }}
      >
        <div className="headerCol borderT">
          <div className="headerRow background-grey">
            <div className="headerCol width-120 borderBottom borderRight">Actual Relief Load</div>
            <div className="headerCol width-80 borderBottom borderRight text-8">Calc. Orifice Area</div>
            <div className="headerCol width-50 borderRight text-8">Calc. <br/> Selected</div>
            <div className="headerCol width-100 borderBottom borderRight">Calc. Selected Orifice Area</div>
            <div className="headerCol width-120 borderBottom borderRight">Existing PSV Rated Relief Load</div>
            <div className="headerCol width-60 borderRight">Existing PSV</div>
            <div className="headerCol width-50 text-8 text-center">Adequate</div>
          </div>
          <div className="headerRow background-grey">
            <div className="headerRow width-120 borderBottom borderRight">
              <div className="width-40 borderRight">kg/hr</div>
              <div className="width-40 borderRight text-8">MMSCFD</div>
              <div className="width-40 ">m3/hr</div>
            </div>
            <div className="headerRow width-80 borderBottom borderRight">
              <div className="width-40 borderRight">mm2</div>
              <div className="width-40">in2</div>
            </div>
            <div className="headerCol width-50 borderBottom borderRight text-8">Orifice Size</div>            
            <div className="headerRow width-100 borderBottom borderRight">
              <div className="width-50 borderRight">mm2</div>
              <div className="width-50">in2</div>
            </div>            
            <div className="headerRow width-120 borderBottom borderRight">
              <div className="width-40 borderRight">kg/hr</div>
              <div className="width-40 borderRight text-8">MMSCFD</div>
              <div className="width-40 ">m3/hr</div>
            </div>
            <div className="width-60 borderRight borderBottom"> Size </div>
            <div className="width-50  text-8 text-center borderBottom">(Yes/No)</div>
          </div>
          {processRows()}
        </div>        
      </div>
    </ReactParser>
  </View>
  <View style={{ width: "100vw", textAlign: "center" }}>
    <StringParser>{data.conclusions}</StringParser>
  </View>
  </ContentTemplate>
}
export default Conclusion