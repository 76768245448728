import React from 'react'
import { GeneratePSVFormV4ReturnObject } from '../../..'
import StringParser from '../core/PageParser/StringParser'
import ContentTemplate from '../core/PageTemplate/ContentTemplate'

const AdditionalNotes = (data: GeneratePSVFormV4ReturnObject) => {
  return <ContentTemplate title="Additional Notes" index={12}>    
    <StringParser>{`<div style="padding:10px;">${data.additionalNotes}</div>`}</StringParser>
  </ContentTemplate>
}
export default AdditionalNotes