import React from 'react'
import { View } from '@react-pdf/renderer'
import { GeneratePSVFormV4ReturnObject } from '../../..'
import ReactParser from "../core/PageParser/ReactParser";
import ContentTemplate from '../core/PageTemplate/ContentTemplate'
import { _thickBorder } from '../core/items';

const FluidComposition = ({data, scenarios}: {data: GeneratePSVFormV4ReturnObject, scenarios:any[]}) => {
  const tableStyles = {}

  return <ContentTemplate title="Fluid Composition" index={5}>        
    <View style={{ width: "100vw", textAlign: "center" }}>
      <ReactParser stylesheet={tableStyles}>
            <div
              style={{
                width: "98vw",
                textAlign: "center",
                margin: "5px",
                display: "flex",
              }}
            >
              <div style={{ border: _thickBorder }}>
                <div className="headerRow borderBottom">
                  <div className="width-250 borderRight">Scenario</div>
                  <div className="width-200 borderRight">Stream Name</div>
                  <div className="width-200">Reference</div>
                </div>
                {
                  data.op.map((op,index,opArr) => {
                    if (op.identified !== true) {return null}
                    else {
                      return <div className={`headerRow ${index !== opArr.length - 1 ? 'borderBottom' : ''}`}>
                        <div className="borderRight width-30">({String.fromCharCode(scenarios.findIndex(x=>x.value === op.scenario.value) + 97)})</div>
                        <div className="borderRight width-220">{op.scenario.label}</div>
                        <div className="borderRight width-200">{op.stream}</div>
                        <div className="width-200"></div>
                      </div>
                    }
                  }).filter(x => x !==null)
                }
              </div>
            </div>
      </ReactParser>
    </View>
  </ContentTemplate>
}
export default FluidComposition