import React from 'react'
import {FormObjectFrameInterface} from "../interfaces"
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap"
import { ErrorMessage } from '@hookform/error-message'

const Frame = (props:FormObjectFrameInterface) => {  
  return (
    <Form.Group className={`mb-3 ${props.className} position-relative`} controlId={`fi-${Math.floor(Math.random() * 500)}`}>
      <Form.Label className="form-captions">
          <span className="text-start">{props.caption}</span>
            {
              props.helperText && <OverlayTrigger 
                placement="left"   
                overlay={(_props) =><Tooltip id={`hp-tt-${props.name}`} {..._props}>{props.helperText}</Tooltip>}><span className="ms-2 fa fa-question-circle"></span></OverlayTrigger>
              
            }
          {props.maxLength && <span style={{float:'right',fontSize:'0.8em'}} className="text-muted text-end">{props.maxLength - (props?.value?.length ?? 0)} character(s) remaining</span>}
                
          <ErrorMessage name={props.name} render={
                ({ message, messages }) => {
                  return message && <OverlayTrigger 
                  placement="left"   
                  overlay={(_props) =><Tooltip id={`hp-tt-${props.name}`} {..._props}>{message}</Tooltip>}>
                    <span  className="ms-2 fa fa-exclamation-circle text-danger"></span></OverlayTrigger>
                  // if (messages !== undefined) {
                  //   const keys = Object.keys(messages); 
                  //   return keys.map((key, index) => <p key={`em-mm-${props.name}-${index}`}>{messages[key]}</p>)
                  // }
              }}/>
      </Form.Label>
      <div className="position-relative">
        {props.children}
      </div>
    </Form.Group>
  )
}

export default Frame