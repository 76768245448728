import { Generate } from './index';

export const massflow_converter_val = {
  'kg/hr' : {
    'kg/h' : (value: number) => Number(value),
    'kg/hr'      : (val:number) =>  Number(val),
    'kg/s'      : (val:number) =>  Number(val) / 3600,
    'lbs/hr' : (val:number) => Number(val) * 2.2046,
    'lbs/s' : (val:number) => Number(val) * 2.2046 / 3600,
  },
  'kg/s' : {'kg/hr' : (val:number) => Number(val) * 3600},
  'kg/h' : {'kg/hr' : (val:number) => Number(val)},
  'lbs/hr' : {'kg/hr' : (val:number) => Number(val) / 2.2046},
  'lbs/s' : {'kg/hr' : (val:number) => Number(val) / 2.2046 / 3600},
}

export type massflow_type = Generate<keyof typeof massflow_converter_val>
export const massflow_types = Object.keys(massflow_converter_val)

export const convertMassflow = (num : number, from: keyof typeof massflow_converter_val, to: keyof typeof massflow_converter_val) => {
  if (from === undefined || to === undefined) { return 0}
  const _base_val:number = from === "kg/hr" ? Number(num) : massflow_converter_val[from]['kg/hr'](num)

  return to === "kg/hr" ? _base_val : massflow_converter_val['kg/hr'][to](_base_val)
}