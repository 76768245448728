import React from "react";
import { Col, Row } from "react-bootstrap";
import { UserContext, userObject } from "../../../App";
import { POSTRequest } from "../../../components/apiHandler";
import { Form, Line, SubmitButton } from "../../../components/Form";

const LoginPage = () => {
  const {setUser} = React.useContext(UserContext) 

  const login = (loginData: { email: string; password: string }) =>
    new Promise((resolve, reject) => {
      POSTRequest<{ status: boolean; data: userObject }>(
        "user.php",
        "LOGIN",
        loginData
      ).then((response) => {
        if (response.status) {
          localStorage.setItem("synergy-login", JSON.stringify(response.data));
          setUser(response.data);
        } else {
          alert("Incorrect Login Information!");
        }
      });
    });
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(230deg, #0038c4, #0077f91a)",
      }}
    >
      <Row
        style={{
          background: "white",
          boxShadow: "1px 5px 10px 0px",
        }}
        className="w-75 p-4 text-center m-2 border-primary rounded"
      >
        <Col xs={12} className="h4 fw-bold pb-3 text-center">
          <img src="/assets/img/logo.png" height="150" alt="Synergy" />
        </Col>
        <Col xs={12} className="h4 fw-bold pb-3">
          Please Login To Continue
        </Col>
        <Col xs={12}>
          <Form onSubmit={login}>
            <Row>
              <Col xs={12}>
                <Line name="email" caption="Email" />{" "}
              </Col>
              <Col xs={12}>
                <Line name="password" type="password" caption="Password" />
              </Col>
              <Col xs={12}>
                <SubmitButton caption="Login" />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
