import { HysysTabFormItems } from './../generate_new_psv/v4/components/Tabs/HysysTab';
import { convertMassflow, massflow_type } from './../../../components/Form/Special/Converters/js/massflow';
import { convertTemperature, temperature_type } from './../../../components/Form/Special/Converters/js/temperature';
import { pressure_type } from './../../../components/Form/Special/Converters/js/pressure';
import { ScenariosTabFormItems } from './../generate_new_psv/v4/components/Tabs/ScenariosTab';
import { GeneratePSVFormV4ReturnObject } from "../generate_new_psv/v4";
import { calculate_orifice_area, calculate_overpressure_b, isCriticalPressure_2 } from './components';
import { convertPressure } from '../../../components/Form/Special/Converters/js';
import { convertArea } from '../../../components/Form/Special/Converters/js/area';
import { ConverterOutputObject } from '../../../components/Form/Special/Converters/components/ConverterInput';
import { logger } from '../../../components/js';

interface prepare_pressure_interface {barg:number,kpa:number,psig:number}
interface prepare_massflow_interface {kghr:number,lbshr:number}
interface prepare_temperature_interface {K:number, F:number, C:number}
type unit_value = {unit:  string, value: number}
interface essential_hysys_interface {
  mol_weight:number,
  k : number, 
  z:number,
  molar_flow:unit_value,
  temperature:unit_value, 
  mass_flow:unit_value
}

const prepare_pressure = (pressure_item : ConverterOutputObject):prepare_pressure_interface => {
  const barg = convertPressure(pressure_item.value,pressure_item.unit.label as pressure_type,"barg")
  const psig = convertPressure(barg,"barg","psig")
  const kpa = convertPressure(barg,"barg","kpa")

  return {barg,psig,kpa}
}

const prepare_temparature = (temperature_item : {value:number,unit:string}):prepare_temperature_interface => {
  const C = convertTemperature(temperature_item.value,temperature_item.unit as temperature_type,"C")
  const F = convertTemperature(C, "C", "F")
  const K = convertTemperature(C,"C","K")

  return {K,F,C}
}

const prepare_accumulation = (set_pressure:prepare_pressure_interface, isFire:boolean, isMultiple:boolean) => {  
  const barg = calculate_overpressure_b(set_pressure.barg,isFire,isMultiple)
  const psig = calculate_overpressure_b(set_pressure.psig,isFire,isMultiple)
  const kpa = calculate_overpressure_b(set_pressure.kpa,isFire,isMultiple)

  return {barg,psig,kpa}
}

const get_hysys_data = (type:'pre'|'to'|'outlets', data : HysysTabFormItems):essential_hysys_interface => {
  if(Array.isArray(data[type])) {
    return {mol_weight:0,molar_flow:{value:0,unit:'MMSCFD'},k : 0, z:0, temperature: {value:0,unit:'C'},mass_flow:{value:0,unit:'kgh'}}
  } else {
    return {
      k: data[type].value.PROPERTIES['cp/(cp-r)'].overall,
      z: data[type].value.PROPERTIES['zfactor'].overall, 
      mol_weight: data[type].value.PROPERTIES['molecularweight'].overall, 
      molar_flow : {value: data[type].value.CONDITIONS.molarflow.overall, unit: data[type].value.CONDITIONS.molarflow.unit}, 
      mass_flow : {value: data[type].value.CONDITIONS.massflow.overall, unit : data[type].value.CONDITIONS.massflow.unit}, 
      temperature : {value : data[type].value.CONDITIONS.temperature.overall, unit : data[type].value.CONDITIONS.temperature.unit}
    }
  }
}

const prepare_mass_flow = (massflow : {unit:string, value:  number}):prepare_massflow_interface => {
  const kghr = convertMassflow(massflow.value,massflow.unit as massflow_type, "kg/hr")
  const lbshr = convertMassflow(kghr,"kg/hr","lbs/hr")
  return {kghr, lbshr}
}

const prepare_area = (
  type: 'critical'|'subcritical',
  valve_type : string,
  K : number,
  accumulation : number, 
  backpressure:number,
  mass_flow:number,
  temperatureK : number,
  compressibility : number,
  molecular_weight: number,
  hasPRV : boolean,
  hasRuptureDisk : boolean
) => {
  const mm2 = calculate_orifice_area(type,backpressure,accumulation,mass_flow,temperatureK,compressibility,molecular_weight, valve_type, K,hasPRV,hasRuptureDisk)
  const in2 = convertArea(mm2,"mm2","sqin")

  return {mm2, in2}
}

type orificeValues = {
  or_id : number,
  or_designation : number,
  or_eff_area : number
}

export const calculateOrificeArea = (data : GeneratePSVFormV4ReturnObject, currentScenario : ScenariosTabFormItems) => {
  
  const isFire = currentScenario.scenario.label === "Fire"
  const isMultiple = data.hysys.outlets.length > 1

  const hysys = get_hysys_data('to',data.hysys)
  // Pressure
  const set_pressure = prepare_pressure(data.valve.pressure.set)
  const back_pressure = prepare_pressure(data.valve.pressure.backpressure)
  const accumulation = prepare_accumulation(set_pressure, isFire,isMultiple)
  logger.log("SetPressure : ",set_pressure)
  logger.log("BackPressure : ",back_pressure)
  logger.log("Accumulation : ",back_pressure)
  // Temperature
  const set_temperature = prepare_temparature(hysys.temperature)
  // Mass flow
  const mass_flow = prepare_mass_flow(hysys.mass_flow)

  const isCritical = isCriticalPressure_2(accumulation.kpa,back_pressure.kpa,hysys.k)
  return prepare_area(isCritical ? 'critical' : 'subcritical',data.valve.type.label, hysys.k,accumulation.kpa,back_pressure.kpa,mass_flow.kghr,set_temperature.K,hysys.z,hysys.mol_weight,true,data.valve.rupture).mm2  

}

export const resultsSuite = ( data : GeneratePSVFormV4ReturnObject, currentScenario : ScenariosTabFormItems) => {
  const isFire = currentScenario.scenario.label === "Fire"
  const isMultiple = data.hysys.outlets.length > 1
  logger.log("Hysis Data", data.hysys.to)
  // Hysys
  const hysys = get_hysys_data('to',data.hysys)
  // Pressure
  const set_pressure = prepare_pressure(data.valve.pressure.set)
  const back_pressure = prepare_pressure(data.valve.pressure.backpressure)
  const accumulation = prepare_accumulation(set_pressure, isFire,isMultiple)

  // Temperature
  const set_temperature = prepare_temparature(hysys.temperature)

  // Mass flow
  const mass_flow = prepare_mass_flow(hysys.mass_flow)

  // Molar Flow
  const molar_flow_mmscfd = Number(data.hysys.to.value.CONDITIONS.molarflow.overall)

  // Area
  // Check criticality
  const isCritical = isCriticalPressure_2(set_pressure.psig,back_pressure.psig,hysys.k)
  const area = prepare_area(isCritical ? 'critical' : 'subcritical',data.valve.type.label, hysys.k,accumulation.kpa,back_pressure.kpa,mass_flow.kghr,set_temperature.K,hysys.z,hysys.mol_weight,true,data.valve.rupture)
  
  return {
    flow : {
      mass : {
        kgh : mass_flow.kghr.toFixed(0),
        lbh : mass_flow.lbshr.toFixed(0)
      },
      molar : {
        mmscfd : molar_flow_mmscfd.toFixed(2)
      }
    },
    pressure : {
      set : {
        barg : set_pressure.barg.toFixed(1),
        psig : set_pressure.psig.toFixed(1),
      },
      accumulation : {
        barg : accumulation.barg.toFixed(1),
        psig : accumulation.psig.toFixed(1)
      }
    },
    temperature : {
      c : set_temperature.C.toFixed(1),
      f : set_temperature.F.toFixed(1)
    },
    area : {
      mm2 : area.mm2.toFixed(2),
      in2 : area.in2.toFixed(2)
    }
  }
  
}


export const conclusionSuite = (orifices : orificeValues[], data : GeneratePSVFormV4ReturnObject, currentScenario : ScenariosTabFormItems) => {
  logger.log("CONCLUSION_SUITE : ",orifices)
  const results = resultsSuite(data,currentScenario)
  
  const nextLargestDesignation = orifices.filter(orifice => Number(orifice.or_eff_area) >= Number(results.area.mm2))[0]
  const chosenDesignation = orifices.filter(orifice => Number(orifice.or_eff_area) >= Number(data.valve.orifice.value))[0]
  logger.log("ORIFICES - ",orifices)
  logger.log("[NEXTLARGESTDESIGNATION] - ",nextLargestDesignation)
  return {
    ...results,
    orifice : {
      calculated : {
        designation : nextLargestDesignation.or_designation, 
        areaCalc : results.area, 
        areaDesignation: {
          mm2 : Number(nextLargestDesignation.or_eff_area).toFixed(1),
          in2 : convertArea(nextLargestDesignation.or_eff_area,"mm2","sqin").toFixed(1)
        }
      },
      chosen : {
        designation : chosenDesignation.or_designation, 
        chosenArea : {
          mm2: Number(data.valve.orifice.value).toFixed(1), 
          in2 : convertArea(data.valve.orifice.value, "mm2","sqin").toFixed(1)
        }
      }
    }
  }
 
}

