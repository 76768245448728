import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Frame from '../../Components/Frame';
import { FormLinesInterface } from '../../interfaces';

const QuillLines = (props: FormLinesInterface) => {
  const methods = useFormContext();
  
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      [ 'image', 'formula'],
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'formula'
  ]

  return <Controller
    control={methods.control}
    name={props.name}
    rules={{ required: props.required }}
    render={({
      field: { onChange, onBlur, value, name, ref },
      fieldState: { invalid, isTouched, isDirty, error },
      formState,
    }) => (
      <Frame {...props}>
        <ReactQuill modules={modules} formats={formats} theme="snow" value={value} onChange={onChange} />
      </Frame>
    )}
  />
}

export default QuillLines