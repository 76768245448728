import React from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";

/* Reports */
// PSV
// import GeneratePSV from './content/report/psv/generate';
// import ViewPSV from './content/report/psv/view';
// import TestPSV from './content/report/psv/test';
// import PSV from './content/report/psv';

/* Standards */
// API 526
import StandardOrifice from "./content/standards/api/526/Orifice";
import MaterialSpec from "./content/standards/api/526/MaterialSpec";
import ValveBody from "./content/standards/api/526/Valve_Body";

import FlangeRating from "./content/standards/ansi/asme/b16_34/flange_ratings";
import Flange from "./content/standards/ansi/asme/flange";
import B3610Schedule from "./content/standards/ansi/asme/b16_36/schedules";
import B3610Specifications from "./content/standards/ansi/asme/b16_36/Specifications";
import Test from "./content/report/psv/test";
import Logout from "./content/user/logout";
import CreateUser from "./content/user/create";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GeneratePSV = React.lazy(() => import("./content/report/psv/generate"));
const ViewPSV = React.lazy(() => import("./content/report/psv/view"));
const TestPSV = React.lazy(() => import("./content/report/psv/test/test2"));
const PSV = React.lazy(() => import("./content/report/psv"));
const AddPSVReport = React.lazy(
  () => import("./content/report/psv/generate/v4")
);

registerLicense(
  "ORg4AjUWIQA/Gnt2VVhiQlFadVZJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJhWX9Wc3ZXQmdaVE0="
);

const SuspenseWrapper = (prop: { children: React.ReactElement }) => (
  <React.Suspense fallback={<div>Loading....</div>}>
    {prop.children}
  </React.Suspense>
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={
          <React.StrictMode>
            <App />            
            <ToastContainer />
          </React.StrictMode>
        }
      >
        <Route path="user">
          <Route path="logout" element={<Logout />} />
          <Route path="create" element={<CreateUser />}/>
        </Route>

        <Route path="projects">
          <Route path="add" />
          <Route path="view" />
          <Route path="view/:projectID" />
          <Route path="view/:projectID/:viewType" />
        </Route>
        <Route path="report">
          <Route
            path="psv"
            element={
              <SuspenseWrapper>
                <PSV />
              </SuspenseWrapper>
            }
          >
            <Route
              path="test"
              element={
                <SuspenseWrapper>
                  <TestPSV />
                </SuspenseWrapper>
              }
            />
            <Route
              path="generate"
              element={
                <SuspenseWrapper>
                  <GeneratePSV />
                </SuspenseWrapper>
              }
            />
            <Route
              path="generate/v4"
              element={
                <SuspenseWrapper>
                  <AddPSVReport />
                </SuspenseWrapper>
              }
            />
            <Route path="test/v4" element={<Test />} />
            <Route
              path="view"
              element={
                <SuspenseWrapper>
                  <ViewPSV />
                </SuspenseWrapper>
              }
            />
          </Route>
        </Route>
        <Route path="standards">
          <Route path="ansi">
            <Route path="asme">
              <Route path="flange" element={<Flange />} />
              <Route path="b16_34" element={<FlangeRating />} />
              <Route path="b16_36">
                <Route path="schedule" element={<B3610Schedule />} />
                <Route path="specs" element={<B3610Specifications />} />
              </Route>
            </Route>
          </Route>

          <Route path="material" element={<MaterialSpec />} />
          <Route path="api">
            <Route path="520"></Route>
            <Route path="521"></Route>
            <Route path="526">
              <Route path="orifice" element={<StandardOrifice />} />
              <Route path="valve_body" element={<ValveBody />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(logger.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
