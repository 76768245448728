import { logger } from '../../../../js';
import { Generate } from './index';

export const area_converter_val = {
  'mm2' : {
    sqin      : (val:number) => Number(val) * 0.001549907005579665
  },
  sqin : {'mm2' : (val:number) => Number(val) / 0.001549907005579665}
}

export type area_type = Generate<keyof typeof area_converter_val>
export const area_types = Object.keys(area_converter_val)

export const convertArea = (num : number, from: keyof typeof area_converter_val, to: keyof typeof area_converter_val) => {
  if (from === undefined || to === undefined) { return 0}
  const _base_val:number = from === "mm2" ? Number(num) : area_converter_val[from]['mm2'](num)
  logger.log(`ConvertArea - Num : ${num}, from : ${from}, to: ${to}`)
  return to === "mm2" ? _base_val : area_converter_val['mm2'][to](_base_val)
}