import React from 'react'
import { GeneratePSVFormV4ReturnObject } from '../../..'
import StringParser from '../core/PageParser/StringParser'
import ContentTemplate from '../core/PageTemplate/ContentTemplate'

const Recommendations = (data: GeneratePSVFormV4ReturnObject) => {
  return <ContentTemplate title="Recommendations" index={10}>    
    <StringParser>{data.recommendations}</StringParser>
  </ContentTemplate>
}
export default Recommendations