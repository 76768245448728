import { logger } from './../../../../components/js/index';
import { pressure_type } from './../../../../components/Form/Special/Converters/js/pressure';
import { ConverterOutputObject } from "../../../../components/Form/Special/Converters/components/ConverterInput"
import { convertPressure } from "../../../../components/Form/Special/Converters/js"

export const overpressure_percent = (isFire?:boolean,isMultiple?:boolean) => {
  const _values = [
    {fire: 0.21, nonfire: 0.10},
    {fire: 0.21, nonfire: 0.16},
  ]

  return _values[isMultiple ? 1 : 0][isFire ? 'fire' : 'nonfire']
}
  
export const calculate_overpressure_a = (setPressure: ConverterOutputObject, overPressure_percentage: number, own: ConverterOutputObject) => {
  logger.group("Calculate Overpressure A")
  logger.log("setPressure",setPressure)
  logger.log("overpressure (%)",overPressure_percentage * 100)
  logger.log("own",own)
  if (
    setPressure?.value === undefined || 
    own === undefined ||
    isNaN(overPressure_percentage) ||
    own?.unit?.label === undefined || 
    own?.unit?.label === '' || 
    setPressure?.unit === undefined || 
    overPressure_percentage === undefined
  ) {logger.groupEnd(); return 0}


  const _newpressure = convertPressure(setPressure.value * (1+ overPressure_percentage), setPressure.unit.label as pressure_type, own.unit.label as pressure_type)
  logger.log("Result : ",_newpressure);
  logger.groupEnd()  
  return _newpressure
} 

export const calculate_overpressure_b = (setPressure: number, isFire:boolean,isMultiple?:boolean, ) => setPressure * (1 + overpressure_percent(isFire,isMultiple))

export const calculate_flow_pressure = (relieving_condition:any, k : any, own : any) => {
  logger.group("Calculate Flow Pressure")
  if (relieving_condition === undefined || isNaN(k) || own?.unit === undefined) {logger.groupEnd(); return 0}
  logger.log("Relieving Pressure : ",relieving_condition)
  logger.log("k Value : ",k)
  logger.log("own : ",own)
  const _rc = relieving_condition ? Number(relieving_condition?.value) : 0
  const _k = k ? Number(k) : 0
  const rawPressure = _rc * Math.pow((2 / (_k + 1)) ,(_k / (_k - 1)))
  logger.log(`Raw Values : 
  rc : ${_rc} 
  k : ${_k}, 
  (k/( k - 1)) : ${(_k / (_k - 1))}, 
  2/k+1 : ${2/(_k+1)}, 
  (2/k+1)^(k/k-1) : ${Math.pow((2 / (_k + 1)) ,(_k / (_k - 1)))}`)
  logger.log("Raw Pressure (Not Converted)",rawPressure)
  logger.groupEnd()
  return convertPressure(rawPressure,relieving_condition.unit.label as pressure_type, own.unit.label as pressure_type)
}

export const isCriticalPressure = (relieving_pressure : any, critical_pressure: any) => {
  logger.group("Is Critical Pressure")
  if (relieving_pressure.value === undefined || critical_pressure.value === undefined) {return "No"}
  logger.log("values : Relieving",relieving_pressure)
  logger.log("values : critical",critical_pressure)
  const _relieving_pressure = convertPressure(relieving_pressure.value, relieving_pressure.unit.label as pressure_type, "kpa")
  const _critical_pressure = convertPressure(critical_pressure.value, critical_pressure.unit.label as pressure_type, "kpa")
  logger.log("values : Relieving (Converted)",_relieving_pressure)
  logger.log("values : critical (Converted)",_critical_pressure)

  logger.groupEnd()
  return _relieving_pressure <= _critical_pressure ? "Yes" : "No"
}

export const calculate_critical_pressure = (k: number, relieving_pressure : number) => {
  const _k = Number(k)
  const _rp = Number(relieving_pressure)

  return Math.pow(2 / (_k + 1),_k / (_k - 1)) * _rp
}

export const isCriticalPressure_2 = (relieving_pressure:number, backpressure_pressure : number, k : number) => {
  const _k = Number(k)
  const _rp = Number(relieving_pressure)
  const _bp = Number(backpressure_pressure)
  const criticalPressure = calculate_critical_pressure(_k,_rp)
  return _bp < criticalPressure
}
// export const calculate_overpressure = (setPressure: number, isFire:boolean,dev_no?:number,isMultiple?:boolean, isSupplimentary?:boolean) => 
//   setPressure * (1 + overpressure_percent(isFire,dev_no,isMultiple,isSupplimentary))