import { logger } from './../../../../js/index';
import { Generate } from './index';

export const pressure_converter_val = {
  kpa : {
    psig      : (val:number) =>  (Number(val) / 6.89476) - 14.7,
    psia      : (val:number) =>  Number(val) / 6.89476,
    bara      : (val:number) =>  Number(val) / 100,
    barg      : (val:number) =>  (Number(val) - 101.3) / 100,
    pa        : (val:number) =>  (Number(val) * 1000),  
    kpag      : (val:number) =>  Number(val) - 101.3,      
    "kg/cm2"  : (val:number) =>  Number(val) / 98.0665
  },
  psig      : {kpa : (val:number) => (Number(val) + 14.7) * 6.89476},
  psia      : {kpa : (val:number) => Number(val) * 6.89476,},
  bara      : {kpa : (val:number) => Number(val) * 100},
  barg      : {kpa : (val:number) => (Number(val) * 100) + 101.3},
  pa        : {kpa : (val:number) =>  Number(val) / 1000},
  kpag      : {kpa : (val:number) =>  Number(val) - 101.3},
  "kg/cm2"  : {kpa : (val:number) => Number(val) * 98.066},
}

export type pressure_type = Generate<keyof typeof pressure_converter_val>
export const pressure_types = Object.keys(pressure_converter_val)

export const convertPressure = (num : number, from: keyof typeof pressure_converter_val, to: keyof typeof pressure_converter_val) => {
  if (from === undefined || to === undefined) { return 0}
  if (from === to) {return num}
  logger.group("convertPressure")
  logger.log(`
  Number : ${num},
  from : ${from} [${from === "kpa"}],
  to : ${to} [${to === "kpa"}],
  `)
  const _base_val:number = from === "kpa" ? Number(num) : pressure_converter_val[from].kpa(num)
  logger.groupEnd()
  return to === "kpa" ? _base_val : pressure_converter_val.kpa[to](_base_val)
}