import React  from 'react';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ConfirmationDialogType } from './interface';

import {Row,Col} from "react-bootstrap"
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';


export const YesNoDialog = React.forwardRef<ConfirmationDialogType, any>((props:ConfirmationDialogType, ref:any) => {
  const header = () => {
    return "Please Confirm Your Action"
  }

  const footer = () => {
    return <Row>
      <Col xs={12} sm={6}><ButtonComponent onClick={()=>props.onChosen(true)} cssClass="e-success">Yes</ButtonComponent></Col>
      <Col xs={12} sm={6}><ButtonComponent onClick={()=>props.onChosen(false)} cssClass="e-danger">No</ButtonComponent></Col>
    </Row>
  }

  return <DialogComponent 
    width={props.width ?? '350px'} 
    target={props.target ?? '#dialog-target'} 
    header={header} 
    footerTemplate={footer} 
    showCloseIcon={true} 
    open={props.open} 
    close={props.close} 
    ref={ref}
  >
  <div className="dialogContent">
    <p>Do you want to do the following ? </p>
    <p>{props.content}</p>
  </div>
</DialogComponent>
})

export const ProgrammaticalYesNoDialog = (content:HTMLElement | string | undefined, onChosen: (a:boolean) => void) => DialogUtility.confirm({
  animationSettings: { effect: 'Zoom' },
  cancelButton: { text: 'Cancel', click: () => onChosen && onChosen(false) },
  closeOnEscape: true,
  content: content,
  okButton: { text: 'OK', click: () => onChosen && onChosen(true) },
  showCloseIcon: true,
  title: ' Confirmation Dialog',
});