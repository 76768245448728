import React from 'react'
import { GeneratePSVFormV4ReturnObject } from '../../..'
import ContentTemplate from '../core/PageTemplate/ContentTemplate'
import ReactParser from "../core/PageParser/ReactParser";
import { borderDefault, _thickBorder } from '../core/items';
import { View } from '@react-pdf/renderer';
// import PdfFlexTableGenerator from '../core/PageTemplate/FlexTable'

const EquipmentData = (data: GeneratePSVFormV4ReturnObject) => {
  const keyWidth = "150px"
  const valueWidth = "400px"

  const tableStyles = {
    ".headerOne": {paddingLeft:'5px', fontWeight:'bold', textAlign:'left'},
    ".detailRows": { borderTop: borderDefault, display:'flex',flexDirection: "row" },
    ".detailRows > div:first-child": { width:keyWidth, padding: "1px 0px", borderRight: borderDefault },
    ".detailRows > div:last-child": { width:valueWidth, padding: "1px 0px" },
    p: { margin: "1px 0px" },
  };

  return <ContentTemplate title="Control Valve Data" index={6}>  
    <View style={{ width: "100vw", textAlign: "center" }}>
      <ReactParser stylesheet={tableStyles}>
            <div
              style={{
                width: "98vw",
                textAlign: "center",
                margin: "5px",
                display: "flex",
              }}
            >
              <div style={{ border: _thickBorder }}>
                  <div className="headerOne">Equipment Data</div>
                  {
                    data.equipment_data.map(item => (
                      <div className="detailRows">
                        <div className="background-grey width-150 borderRight">{item.param}</div>
                        <div className="background-grey width-50 borderRight">{item.unit ?? '-'}</div>
                        <div className="width-150">{item.value}</div>
                      </div>
                    ))
                  }
              </div>
            </div>
      </ReactParser>
    </View>
  </ContentTemplate>
}
export default EquipmentData

  
