import React from 'react'
import Html from 'react-pdf-html';
// import { parseContentBlock } from './components/core';

interface StringParserProps {
  children : string
}

const StringParser = (props: StringParserProps) => { 

  return (
    <Html style={{ fontSize: '10px' }}>{props.children}</Html>
  )
}

export default StringParser