import React from 'react'
import {  Document, PDFViewer } from '@react-pdf/renderer'
import { GeneratePSVFormV4ReturnObject } from '../..'
import { contentPageItems } from './ContentPage'
import { pdfFrontPage } from './FrontPage'
import PDFGenerator from './generator'

interface ReportPDFProps {
  data : GeneratePSVFormV4ReturnObject,
  reportValues : {scenarios:any[], orifices:any[]}
}

export const contentContainerWidth=551

export const create_report_pdf = (
  data: GeneratePSVFormV4ReturnObject, 
  reportValues : {orifices : any[], scenarios: any[]},
  add_appendix :boolean = false,
  version:string = '1',
) => {
  if (version === '1') { 
    if (add_appendix) {
      return <PDFGenerator data={data} reportValues={reportValues} />
    } else {
      return <Document>{pdfFrontPage(data)}{contentPageItems(data, reportValues.orifices, reportValues.scenarios)}</Document>
    }
  }
}

const ReportPDF = (props: ReportPDFProps) => {
  return (
    <>
      <PDFViewer className="w-100 h-100">{create_report_pdf(props.data, props.reportValues)}</PDFViewer> 
    </>
  )
}

export default ReportPDF