import React from 'react'
import { useNavigate } from 'react-router-dom'
import { POSTRequest } from '../../../components/apiHandler'
import { showAlert } from '../../../components/js'
import CreateUserForm, { CreateUserFormReturnObject } from '../../../forms/user/create'
import ContentFrame from '../../../Frame/ContentFrame'


const CreateUser = () => {
  const navigate = useNavigate()
  const addUser = (data:CreateUserFormReturnObject) => new Promise((resolve, reject) => {
    POSTRequest("user.php","CREATE_USER",data)
    .then((res) =>{
      showAlert("User Added successfully! ","success")
      navigate("/")
    })
  })
  return (
    <ContentFrame title="Create User Page">
      <CreateUserForm onSubmit={addUser} />
    </ContentFrame>
  )
}

export default CreateUser