import React from "react";
import { GeneratePSVFormV4ReturnObject } from "../../..";
import {
  convertPressure,
  pressure_type,
} from "../../../../../../../components/Form/Special/Converters/js";
import { logger } from "../../../../../../../components/js";
import { pdfDetailRow } from "../core/items";
import ContentTemplate from "../core/PageTemplate/ContentTemplate";

const PSVData = (data: GeneratePSVFormV4ReturnObject) => {
  logger.log("[Data] - PSV ", data);
  const _setPressure = data.valve.pressure.set;
  const _psig = convertPressure(
    _setPressure.value,
    _setPressure.unit.label as pressure_type,
    "psig"
  );
  const _barg = convertPressure(
    _setPressure.value,
    _setPressure.unit.label as pressure_type,
    "barg"
  );

  return (
    <ContentTemplate title="PSV Data" index={2}>
      {pdfDetailRow(
        "Setpoint",
        `${_barg.toPrecision(4)} barg or ${Number(_psig).toPrecision(4)} psig`,
        " : ",
        { width: "150px" }
      )}
      {pdfDetailRow("Location", data.basic.location, " : ", { width: "150px" })}
    </ContentTemplate>
  );
};

export default PSVData;
