import React from "react";
import { View } from "@react-pdf/renderer";
import { GeneratePSVFormV4ReturnObject } from "../../..";
import ContentTemplate from "../core/PageTemplate/ContentTemplate";
import { logger } from "../../../../../../../components/js";
import ReactParser from "../core/PageParser/ReactParser";
import { borderDefault, _thickBorder } from "../core/items";
import HTMLReactParser from "html-react-parser";
// import PdfTableGenerator from '../core/PageTemplate/Table'

const Scenarios = ({data, scenarios} : {data: GeneratePSVFormV4ReturnObject, scenarios:any[]}) => {
  logger.log("[Data] - PSV ", data);

  // const _columns: itemType[][] = scenarioList.map((scenario, index) => {
  //   const findItem = data.op.filter((o) => o.scenario.value === scenario.value);

  //   const identifier =
  //     findItem.length < 1
  //       ? "N/A"
  //       : findItem[0].governing
  //       ? "GOV"
  //       : findItem[0].identified
  //       ? "ID"
  //       : "N/A";
  //   const remark = findItem.length < 1 ? "" : findItem[0].remark;
  //   logger.log(identifier, remark);
  //   // return [
  //   //   {text:`(${String.fromCharCode(index + 97)})`,styles:{}},
  //   //   {text:scenario.label, styles:{}},
  //   //   {styles:{},text : identifier},
  //   //   {text: remark ?? '', styles:{}}
  //   // ]
  //   return [
  //     {
  //       text: `(${String.fromCharCode(index + 97)})`,
  //       styles: { fontSize: "9px", width: "30px" },
  //     },
  //     { text: scenario.label, styles: { fontSize: "9px", width: "200px" } },
  //     {
  //       styles: { fontSize: "9px", height: "20px", width: "30px" },
  //       text: identifier,
  //     },
  //     { text: remark ?? "", styles: { fontSize: "9px", textAlign: "left" }, type:'html' },
  //   ];
  // });
  // const _columns: React.ReactElement[] = scenarioList.map((scenario, index) => {
  //   const findItem = data.op.filter((o) => o.scenario.value === scenario.value);

  //   const identifier =
  //     findItem.length < 1
  //       ? "N/A"
  //       : findItem[0].governing
  //       ? "GOV"
  //       : findItem[0].identified
  //       ? "ID"
  //       : "N/A";
  //   const remark = findItem.length < 1 ? "" : findItem[0].remark;
  //   logger.log(identifier, remark);
  //   // return [
  //   //   {text:`(${String.fromCharCode(index + 97)})`,styles:{}},
  //   //   {text:scenario.label, styles:{}},
  //   //   {styles:{},text : identifier},
  //   //   {text: remark ?? '', styles:{}}
  //   // ]
  //   return (
  //     <tr>
  //       <td className="smallRow" style={{ width: "80px" }}>
  //         {String.fromCharCode(index + 97)}
  //       </td>
  //       <td>{scenario.label}</td>
  //       <td className="smallRow" style={{ width: "80px" }}>
  //         {identifier}
  //       </td>
  //       <td>{remark ?? ""}</td>
  //     </tr>
  //   );

  //   // [
  //   //   {
  //   //     text: `(${String.fromCharCode(index + 97)})`,
  //   //     styles: { fontSize: "9px", width: "30px" },
  //   //   },
  //   //   { text: scenario.label, styles: { fontSize: "9px", width: "200px" } },
  //   //   {
  //   //     styles: { fontSize: "9px", height: "20px", width: "30px" },
  //   //     text: identifier,
  //   //   },
  //   //   { text: remark ?? "", styles: { fontSize: "9px", textAlign: "left" }, type:'html' },
  //   // ];
  // });

  const _columns_2: React.ReactElement[] = scenarios.map(
    (scenario, index) => {
      const findItem = data.op.filter(
        (o) => o.scenario.value === scenario.value
      );

      const identifier =
        findItem.length < 1
          ? "N/A"
          : findItem[0].governing
          ? "GOV"
          : findItem[0].identified
          ? "ID"
          : "N/A";
      const remark = findItem.length < 1 ? "" : findItem[0].remarks;
      logger.log(identifier, remark);
      // return [
      //   {text:`(${String.fromCharCode(index + 97)})`,styles:{}},
      //   {text:scenario.label, styles:{}},
      //   {styles:{},text : identifier},
      //   {text: remark ?? '', styles:{}}
      // ]
      const remarkHtml = HTMLReactParser(remark ?? '')
      return (
        <div
          className="detailRows"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div className="smallCell">{String.fromCharCode(index + 97)}</div>
          <div className="detailCell" >
            {scenario.label}
          </div>
          <div className="smallCell">{identifier}</div>
          <div className="remarkCell">{remarkHtml}</div>
        </div>
      );

      // [
      //   {
      //     text: `(${String.fromCharCode(index + 97)})`,
      //     styles: { fontSize: "9px", width: "30px" },
      //   },
      //   { text: scenario.label, styles: { fontSize: "9px", width: "200px" } },
      //   {
      //     styles: { fontSize: "9px", height: "20px", width: "30px" },
      //     text: identifier,
      //   },
      //   { text: remark ?? "", styles: { fontSize: "9px", textAlign: "left" }, type:'html' },
      // ];
    }
  );

  const indexWidths = "30px"
  const remarkWidth = "345px"
  const headerWidths = "210px"
  const tableStyles = {
    ".headerOne": {minWidth:headerWidths, maxWidth:headerWidths,width:headerWidths, fontWeight:'bold', borderRight: borderDefault},
    ".headerTwo": {minWidth:remarkWidth, maxWidth:remarkWidth,width:remarkWidth, fontWeight:'bold'},
    ".detailRows": { borderTop: borderDefault },
    ".detailRows > div": { height:'100%', padding: "1px 0px", borderRight: borderDefault },
    ".detailRows > div:last-child": { borderRight: "none" },
    ".smallCell": { minWidth: indexWidths, maxWidth: indexWidths, indexWidths },
    ".detailCell": { minWidth: "150px", maxWidth: "150px", width: "150px", textAlign:'left',paddingLeft:'5px' },
    ".remarkCell": { textAlign:'justify', padding:'1px 5px', minWidth: remarkWidth, maxWidth: remarkWidth, width:remarkWidth },
    p: { margin: "1px 0px" },
  };

  return (
    <ContentTemplate title="Potential Overpressure Scenarios" index={3}>
      <View style={{ width: "100vw", textAlign: "center" }}>
        <ReactParser stylesheet={tableStyles}>
          <div
            style={{
              width: "98vw",
              textAlign: "center",
              margin: "5px",
              display: "flex",
            }}
          >
            <div style={{ border: _thickBorder }}>
              <div style={{display:'flex',flexDirection: "row"}}>
                <div className="headerOne">Potential Overpressure Scenarios</div>
                <div className="headerTwo">Remarks</div>
              </div>
              {_columns_2}
            </div>
          </div>
        </ReactParser>
      </View>
    </ContentTemplate>
  );

  //   {`<table style="border:1px solid black; width:90%; text-align:center;">
  //   <thead>
  //     <tr>
  //       <th colspan="4">Potential Overpressure Scenarios</th>
  //       <th >Remarks</th>
  //     </tr>
  //   </thead>
  //   <tbody>${_columns}</tbody>
  // </table>`}
  // return (
  //   <ContentTemplate title="Potential Overpressure Scenarios" index={3}>
  //     <View
  //       style={{
  //         width: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <PdfFlexTableGenerator
  //         headers={[
  //           [
  //             {
  //               text: "Potential Overpressure Scenario",
  //               styles: { backgroundColor: "gainsboro", width: "263px" },
  //             },
  //             { text: "Remarks", styles: { backgroundColor: "gainsboro" } },
  //           ],
  //         ]}
  //         // headers={[[{text:"Potential Overpressure Scenario", styles:{}}, {text:'Remarks',styles:{width:'291px'}}]]}
  //         rows={_columns}
  //         containerStyle={{ width: "551px" }}
  //       />
  //       <View
  //         style={{
  //           width: "100%",
  //           display: "flex",
  //           borderTop: "2px solid gainsboro",
  //           borderBottom: "2px solid gainsboro",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //         }}
  //       >
  //         <Text style={{ margin: "10px" }}>N/A - Not Applicable</Text>
  //         <Text style={{ margin: "10px" }}>ID - Identified</Text>
  //         <Text style={{ margin: "10px" }}>GOV - Governing Scenario</Text>
  //       </View>
  //     </View>
  //   </ContentTemplate>
  // );
};

export default Scenarios;
