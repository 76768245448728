import axios from "axios";
import { logger } from "../js";

const isDevelopment = (!process.env.NODE_ENV) || (process.env.NODE_ENV === 'development')
export const base_url = isDevelopment ? "http://api.synergy.test/api/" : "http://synergy-proto.my-augment.com/api/"

export const POSTRequest = <T=any,>(url:string, a:string, data?: any, options : any = {}) => 
new Promise<T>((resolve, reject) => {
  axios.post(
    base_url + url,
    {
      a:  a,
      ...data
    }, 
    {...options}
  ).then(response => {
    if (response.status >=200 && response.status <300 && response.data.status) {
      resolve(response.data.data);
    } else {
      reject(null);
    }
  })
})

export const UploadFile  = (url:string,data?: any) => 
new Promise<any>((resolve, reject) => {
  axios.post(base_url + url, data, {headers: {'Content-Type': 'multipart/form-data'}})
  .then((response)=>resolve(response))
})


export const DownloadFiles = <T=any,>(urls:string[], a:string, data?: any) => 
new Promise<T>((resolve, reject) => {
  const promiseCont:any[] = []

  urls.forEach(url => {promiseCont.push(DownloadFile(url,a,data))})

  Promise.all(promiseCont).then((response :any) => resolve(response))
})

export const DownloadFile = <T=any,>(url:string, a:string, data?: any) => 
new Promise<T|null>((resolve, reject) => {
  axios.post(base_url + url,{a, ...data},{
    responseType:'blob',
    headers: {
      'Content-Type': 'application/pdf',
      'Accept': 'application/pdf'
    }
  })
  .then(response => {
    logger.log("RESPONSE",response)
    if (response.status >=200 && response.status <300 && response.data !== false) {
      resolve(response.data);
    } else {
      resolve(null);
    }
  }).catch(err => {logger.log("ERROR",err)});
})