import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { contentContainerWidth } from "../..";
import {
  borderType,
  calculateFlexWidths,
  isAllowedGroup,
  itemType,
  parseInput,
} from "../PageParser/components/core";
import produce from "immer";
import { Style } from "@react-pdf/types";
import { logger } from "../../../../../../../../components/js";

interface PdfFlexTableGeneratorProps {
  headers: itemType[][];
  rows: itemType[][];
  containerStyle?: object;
  fullWidth?: number;
  outerBorder?: string;
  innerBorder?: string;
  fixed?: boolean;
}

const border = "1px solid black";
const nullBorder = "none";

const PdfFlexTableGenerator = (props: PdfFlexTableGeneratorProps) => {
  const outerBorder = props.outerBorder ?? border;
  const innerBorder = props.innerBorder ?? border;
  // const testBorder = '1px solid red'

  const styles = StyleSheet.create({
    containerStyle: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      margin: "0 0 15px 0",
      padding: 1,
      ...props.containerStyle,
    },
    cellStyle: {
      fontSize: "9px",
      minHeight: "15px",
      flexGrow: 1,
      width: "100%",
      overflow: "hidden",
      listStyle: "none",
    },
  });

  const generateRow = (
    items: itemType[][],
    usedWidth = props.fullWidth ?? contentContainerWidth,
    type: "header" | "row",
    isChildRow = false
  ) => {
    const isLastCell = (row: any[], rowIndex: number) =>
      row.length - 1 <= rowIndex;
    const isFirstRow = (rowIndex: number) => rowIndex === 0;
    // const isLastRow = (rows: any[], rowIndex: number) => rows.length - 1 <= rowIndex
    const hasHeader = () => props.headers.length > 0;

    const isLastRow = (location: "header" | "row", rowIndex: number) => {
      if (location === "header") {
        if (rowIndex === props.headers.length - 1 && props.rows.length === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if (rowIndex === props.rows.length - 1) {
          return true;
        } else {
          return false;
        }
      }
    };
    return items.map((row, rowIndex) => {
      logger.groupCollapsed("GenerateRow Item");
      logger.log("Item : ", row);
      const widths = calculateFlexWidths(row, usedWidth, 0, 10);
      logger.log("widths : ", widths);

      logger.groupEnd();
      return row.map((cell, cellIndex) => {
        logger.groupCollapsed("GenerateRow Cell");

        const preparedStyles = produce({} as Style, (draft) => {
          draft.border = 0;
          draft.paddingLeft = 5;
          const cellBorderStyles = borderType(cell);

          if (type === "header") {
            draft.fontWeight = "bold";
            // First Cell
            if (cellIndex === 0) {
              draft.borderLeft =
                cellBorderStyles[0] !== ""
                  ? cellBorderStyles[0]
                  : isChildRow
                  ? nullBorder
                  : outerBorder;
              draft.borderTop =
                cellBorderStyles[1] !== "" ? cellBorderStyles[1] : outerBorder;
              draft.borderRight =
                cellBorderStyles[2] !== ""
                  ? cellBorderStyles[2]
                  : isLastCell(row, cellIndex) && isChildRow
                  ? nullBorder
                  : outerBorder;
              draft.borderBottom =
                cellBorderStyles[3] !== ""
                  ? cellBorderStyles[3]
                  : isFirstRow(rowIndex)
                  ? nullBorder
                  : outerBorder;
            }

            // 2nd to nth Cell
            else if (cellIndex < row.length - 1) {
              draft.borderLeft =
                cellBorderStyles[0] !== "" ? cellBorderStyles[0] : nullBorder;
              draft.borderTop =
                cellBorderStyles[1] !== "" ? cellBorderStyles[1] : outerBorder;
              draft.borderRight =
                cellBorderStyles[2] !== ""
                  ? cellBorderStyles[2]
                  : isLastCell(row, cellIndex) && isChildRow
                  ? nullBorder
                  : outerBorder;
              draft.borderBottom =
                cellBorderStyles[3] !== ""
                  ? cellBorderStyles[3]
                  : isFirstRow(rowIndex)
                  ? nullBorder
                  : outerBorder;
            }
            // Last
            else {
              draft.borderLeft =
                cellBorderStyles[0] !== "" ? cellBorderStyles[0] : nullBorder;
              draft.borderTop =
                cellBorderStyles[1] !== "" ? cellBorderStyles[1] : outerBorder;
              draft.borderRight =
                cellBorderStyles[2] !== ""
                  ? cellBorderStyles[2]
                  : isLastCell(row, cellIndex) && isChildRow
                  ? nullBorder
                  : outerBorder;
              draft.borderBottom =
                cellBorderStyles[3] !== ""
                  ? cellBorderStyles[3]
                  : isFirstRow(rowIndex)
                  ? nullBorder
                  : outerBorder;
            }
          } else if (type === "row") {
            // First Cell
            if (cellIndex === 0) {
              draft.borderLeft =
                cellBorderStyles[0] !== ""
                  ? cellBorderStyles[0]
                  : isChildRow
                  ? nullBorder
                  : outerBorder;
              draft.borderTop =
                cellBorderStyles[1] !== ""
                  ? cellBorderStyles[1]
                  : hasHeader()
                  ? nullBorder
                  : isFirstRow(rowIndex)
                  ? nullBorder
                  : innerBorder;
              draft.borderRight =
                cellBorderStyles[2] !== ""
                  ? cellBorderStyles[2]
                  : isChildRow && isLastCell(row, cellIndex)
                  ? nullBorder
                  : isLastCell(row, cellIndex)
                  ? outerBorder
                  : innerBorder;
              draft.borderBottom =
                cellBorderStyles[3] !== ""
                  ? cellBorderStyles[3]
                  : isChildRow
                  ? nullBorder
                  : isLastRow(type, rowIndex)
                  ? outerBorder
                  : innerBorder;
            }
            // 2nd to nth Cell
            else if (cellIndex < row.length - 1) {
              draft.borderLeft =
                cellBorderStyles[0] !== "" ? cellBorderStyles[0] : nullBorder;
              draft.borderTop =
                cellBorderStyles[1] !== ""
                  ? cellBorderStyles[1]
                  : isFirstRow(rowIndex)
                  ? nullBorder
                  : innerBorder;
              draft.borderRight =
                cellBorderStyles[2] !== ""
                  ? cellBorderStyles[2]
                  : isChildRow && isLastCell(row, cellIndex)
                  ? nullBorder
                  : isLastCell(row, cellIndex)
                  ? outerBorder
                  : innerBorder;
              draft.borderBottom =
                cellBorderStyles[3] !== ""
                  ? cellBorderStyles[3]
                  : isChildRow
                  ? nullBorder
                  : isLastRow(type, rowIndex)
                  ? outerBorder
                  : innerBorder;
            }
            // Last
            else {
              draft.borderLeft =
                cellBorderStyles[0] !== "" ? cellBorderStyles[0] : nullBorder;
              draft.borderTop =
                cellBorderStyles[1] !== ""
                  ? cellBorderStyles[1]
                  : isFirstRow(rowIndex)
                  ? nullBorder
                  : innerBorder;
              draft.borderRight =
                cellBorderStyles[2] !== ""
                  ? cellBorderStyles[2]
                  : isChildRow && isLastCell(row, cellIndex)
                  ? nullBorder
                  : isLastCell(row, cellIndex)
                  ? outerBorder
                  : innerBorder;
              draft.borderBottom =
                cellBorderStyles[3] !== ""
                  ? cellBorderStyles[3]
                  : isChildRow
                  ? nullBorder
                  : isLastRow(type, rowIndex)
                  ? outerBorder
                  : innerBorder;
            }
          }

          if (!isAllowedGroup(cell) && cell.childRows) {
            draft.display = "flex";
            draft.flexDirection = "row";
            draft.flexWrap = "wrap";
            draft.margin = 0;
            draft.padding = 0;
          }

          if (!isAllowedGroup(cell)) {
            draft = { ...draft, ...cell.styles };
          }
        });

        logger.log("PrparedStyles", preparedStyles);
        logger.log("Styles", {
          ...styles.cellStyle,
          width: widths[cellIndex],
          ...preparedStyles,
        });
        logger.groupEnd();
        return (
          <View
            style={{
              ...styles.cellStyle,
              width: widths[cellIndex],
              ...preparedStyles,
            }}
          >
            {isAllowedGroup(cell) ||
            (!isAllowedGroup(cell) && cell.childRows === undefined)
              ? parseInput(
                  cell,
                  {},
                  isAllowedGroup(cell) ||
                    (!isAllowedGroup(cell) && cell.type === undefined)
                    ? "text"
                    : cell.type
                )
              : cell.childRows
              ? generateRow(
                  cell.childRows,
                  Number(widths[cellIndex].replace(/px/g, "")),
                  type
                )
              : ""}
          </View>
        );
      });
    });
  };

  return (
    <View fixed={props.fixed} style={styles.containerStyle} wrap={false}>
      {generateRow(props.headers, contentContainerWidth, "header")}
      {generateRow(props.rows, contentContainerWidth, "row")}
      {
        // props.headers.map((headers,index) => {
        //   const widths = calculateFlexWidths(headers,props.fullWidth ?? contentContainerWidth,0,10)
        //   return headers.map((header,index) => {
        //     return <View  style={{...styles.cellStyle, width : widths[index]}}>{
        //       parseInput(header, {})
        //       }</View>
        //   })}
        // )
      }
      {
        // props.rows.map((rows,index) => {
        //   const widths = calculateFlexWidths(rows,props.fullWidth ?? contentContainerWidth,0,10)
        //   return rows.map((row,index) =>
        //     <View style={{...styles.cellStyle, width : widths[index]}}>
        //       {parseInput(row, {minHeight:'20px', borderRight: index === rows.length - 1 ? '' : border})}
        //     </View>
        //   )
        // })
      }
    </View>
  );
};

export default PdfFlexTableGenerator;
