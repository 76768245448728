import { GeneratePSVFormV4ReturnObject } from "../../../..";
import { logger } from "../../../../../../../../components/js";
import { itemType } from "../PageParser/components/core";
import PdfFlexTableGenerator from "./FlexTable";

const revisionMaker = (data: GeneratePSVFormV4ReturnObject) => {
  const rows = [];

  if (data.revision) {
    logger.log("REVISION", data.revision);
    rows.push(
      ...data.revision.map((rev, index, allRev) => [
        String.fromCharCode(65 + index),
        { text: new Date(rev.date).toDateString(), styles: { width: "100px" } },
        { text: rev.detail, styles: { width: "200px" } },
        rev.user.originator ?? "-",
        rev.user.checker ?? "-",
        rev.user.approver ?? "-",
      ])
    );
  } else {
    rows.push([
      "A",
      { text: new Date().toDateString(), styles: { width: "100px" } },
      { text: "Issued for Review", styles: { width: "200px" } },
      "CURR",
      "-",
      "-",
    ]);
  }

  rows.push([
    "Rev",
    { text: "Date", styles: { width: "100px" } },
    { text: "Description", styles: { width: "200px" } },
    "Orig",
    "Chk'd",
    "App'd",
  ]);

  return rows;
};

export const ContentPageHeader = (props: {
  data: GeneratePSVFormV4ReturnObject;
  children?: React.ReactElement | React.ReactElement[];
}) => {
  const headerRows: itemType[][] = [
    [
      {
        text: "",
        img: "/assets/img/logo.png",
        styles: { width: "100px", padding: "10px" },
      },
      {
        text: "",
        img: "",
        styles: {},
        childRows: [
          ...revisionMaker(props.data),
          ["SYNERGY ENGINEERING"],
          ["RELIEF VALVE SIZING SUMMARY SHEETS"],
        ],
      },
    ],
    [
      { text: "Client", styles: { width: "150px", borderRight: 0 } },
      { text: props.data.basic.company.label, styles: { color: "blue" } },
    ],
    [
      { text: "Project", styles: { width: "150px", borderRight: 0 } },
      { text: props.data.basic.project.label, styles: { color: "blue" } },
    ],
    [
      { text: "Sheet", styles: { width: "150px", borderRight: 0 } },
      { text: "", styles: { color: "blue" }, type: "sheet" },
    ],
    [
      { text: "PID", styles: { width: "150px", borderRight: 0 } },
      { text: props.data.basic.cal_no, styles: { color: "blue" } },
    ],
    [
      { text: "PSV Tag Name", styles: { width: "150px", borderRight: 0 } },
      { text: props.data.basic.psv_tag, styles: { color: "blue" } },
    ],
  ];
  return (
    <PdfFlexTableGenerator
      fixed
      fullWidth={595}
      headers={headerRows}
      rows={[[]]}
    />
  );
};
