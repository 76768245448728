import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { Document, usePDF } from "@react-pdf/renderer";
import { GeneratePSVFormV4ReturnObject } from "../..";
import { contentPageItems } from "./ContentPage";
import { pdfFrontPage } from "./FrontPage";
import PDFMerger from "pdf-merger-js/browser";
import { UploadFile } from "../../../../../../components/apiHandler";
import { FormSelectOptionsInterface } from "../../../../../../components/Form/interfaces";
import { Buffer } from "buffer";
import { logger } from "../../../../../../components/js";

interface PDFGeneratorProps {
  data: GeneratePSVFormV4ReturnObject;
  reportValues: {
    orifices: FormSelectOptionsInterface[];
    scenarios: FormSelectOptionsInterface[];
  };
}

export const contentContainerWidth = 551;

const PDFStringToFile = (pdfString: string) => {
  var byteCharacters = Buffer.from(pdfString, "base64");
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.readUint8(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var file = new Blob([byteArray], { type: "application/pdf;base64" });
  return file;
};

const promisableArray = (jspdfArr: jsPDF) =>
  new Promise<ArrayBuffer>((resolve, reject) => {
    resolve(jspdfArr.output("arraybuffer"));
  });

const PDFGenerator = (props: PDFGeneratorProps) => {
  const [final, setFinal] = useState<Blob | null>(null);
  const [saved, setSaved] = useState<boolean>(false);
  var merger = new PDFMerger();
  //eslint-disable-next-line
  const [instance, updateInstance] = usePDF({
    document: (
      <Document>
        {pdfFrontPage(props.data)}
        {contentPageItems(
          props.data,
          props.reportValues.orifices,
          props.reportValues.scenarios
        )}
      </Document>
    ),
  });

  useEffect(() => {
    const render = async () => {
      if (instance.blob !== null) {
        const files = [];
        files.push(instance.blob.arrayBuffer());

        props.data.appendix.forEach(async (appendix) => {
          const doc = new jsPDF();
          doc.setFontSize(40);
          autoTable(doc, {
            columnStyles: {
              0: {
                halign: "center",
                lineColor: [0, 0, 0],
                lineWidth: 1,
                fontSize: 22,
              },
            }, // Cells in first column centered and green
            margin: { top: 150 },
            body: [[appendix.name]],
          });
          files.push(promisableArray(doc));
          files.push(
            typeof appendix.file.rawFile === "string"
              ? PDFStringToFile(appendix.file.rawFile).arrayBuffer()
              : appendix.file.rawFile.arrayBuffer()
          );
        });
        const _files = await Promise.all(files);
        logger.log("[pdf]Files - ", _files);
        for (const file of _files) {
          logger.log("[pdf]File-", file);
          await merger.add(file);
        }

        const _blob = await merger.saveAsBlob();
        setFinal(_blob);
      }
    };

    render().catch((err) => logger.log(err));
    //eslint-disable-next-line
  }, [instance.blob]);

  useEffect(() => {
    if (!saved && final !== null) {
      var formData = new FormData();
      formData.append("null", final);
      const url = `uploadHandler.php?action=SAVE&path=uploads/psv/${
        props.data.psv_id ?? "0"
      }/&filename=${props.data.psv_id ?? "0"}_${new Date()
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "")}.pdf`.replace("/", "%2F");
      UploadFile(url, formData).then(() => {
        setSaved(true);
      });
    }
    //eslint-disable-next-line
  }, [final]);

  return final === null ? (
    <p>Loading...</p>
  ) : (
    <iframe
      height={1000}
      src={`${URL.createObjectURL(final)}`}
      title="pdf-viewer"
      width="100%"
    ></iframe>
  );
};

export default PDFGenerator;
