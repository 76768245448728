import React from "react";
import { AugTable } from "../../../../../components/Table";
import ContentFrame from "../../../../../Frame/ContentFrame";

const MaterialSpec = () => {
  return (
    
  <ContentFrame title="API 526 Material Datasheet" >
    <AugTable
      allowResizing
      allowReordering
      allowEditing
      editOptions={{
        allowEditing: true,
        allowAdding: true,
        showConfirmDialog: true,
        showDeleteConfirmDialog: true,
        allowDeleting: true,
        mode: "Dialog",
      }}
      columns={[
        { field: "bm_id", headerText: "ID" , isIdentity:true},
        { field: "bm_name", headerText: "Name", width: 300},
        { field: "bm_standard", headerText: "Grade"},
        { field: "bm_min_temp", headerText: "Minimum Temperature (F)" },
        { field: "bm_max_temp", headerText: "Maximum Temperature (F)" },
        { field: "bm_roughness", headerText: "Roughness" },
        { field: "bm_dateCreated", headerText: "Date Created" },
      ]}
      dataManagerUrl={{
        url: "standards/material_spec.php?action=INIT",
        insertUrl: "standards/material_spec.php?action=ADD",
        removeUrl: "standards/material_spec.php?action=REMOVE",
        updateUrl: "standards/material_spec.php?action=UPDATE",
      }}
    />
  </ContentFrame>
  );
};

export default MaterialSpec;
