import React from "react";
import { GeneratePSVFormV4ReturnObject } from "../../..";
import ContentTemplate from "../core/PageTemplate/ContentTemplate";
import ReactHtmlParser from "react-html-parser";
import StringParser from "../core/PageParser/StringParser";
import { logger } from "../../../../../../../components/js";

const CalculationBasisNAssumptions = (data: GeneratePSVFormV4ReturnObject) => {
  const _assumptions = ReactHtmlParser(data.assumptions);
  logger.log("[assumptions] - _assumptions", _assumptions);
  return (
    <ContentTemplate title="Calculation Basis & Assumptions" index={4}>
      <StringParser>{data.assumptions}</StringParser>
    </ContentTemplate>
  );
};

export default CalculationBasisNAssumptions;
