import { Text, View, StyleSheet  } from "@react-pdf/renderer"
import { _thickBorder } from "../items"

export const SectionHeader = (props: {title:string, index:number, headerStyle?: object}) => {
  const _styles = StyleSheet.create({
    headerStyle :  {height:'20px',minHeight:'20px',fontWeight:'bold',display:'flex',flexDirection:'row',fontSize:'12px',borderBottom:_thickBorder, width:'100%', backgroundColor:'#c6eaf7', borderTop: (props.index ===1 ? '' : _thickBorder),...props.headerStyle},
    sectionStyle : {paddingLeft:'10px',width:'60px'},
    titleStyle : {}
  })
  return <View wrap={false} style={_styles.headerStyle}>
    <View style={_styles.sectionStyle}><Text>{props.index.toFixed(1)}</Text></View>
    <View style={_styles.titleStyle}><Text>{props.title}</Text></View>
  </View>
}