import React from 'react'
import { AugTable } from '../../../../../../components/Table'
import {Row,Col} from "react-bootstrap"

import ContentFrame from '../../../../../../Frame/ContentFrame'

const B3610Schedule = () => {

  return (
    <ContentFrame title="ASME B36.10M Schedules">
      <Row>
        <Col xs={12}>
          <AugTable
            allowResizing
            allowReordering
            allowEditing
            editOptions={{
              allowEditing: true, 
              allowAdding: true,
              showConfirmDialog: true,
              showDeleteConfirmDialog: true,
              allowDeleting: true,
              mode: "Dialog",
            }}
            columns={[
              {field : 'b3610_sc_id',headerText : 'ID', isIdentity: true},
              {field : 'b3610_sc_schedule',headerText : 'Schedule'},
            ]}
            dataManagerUrl={{
              url: "standards/asme/b3610m/pipe_schedules.php?action=INIT",
              insertUrl: "standards/asme/b3610m/pipe_schedules.php?action=ADD",
              removeUrl: "standards/asme/b3610m/pipe_schedules.php?action=REMOVE",
              updateUrl: "standards/asme/b3610m/pipe_schedules.php?action=UPDATE",
            }}
        />
        </Col>
      </Row>
    </ContentFrame>
  )
}

export default B3610Schedule