import React from 'react'
import {FormObjectFrameInterface} from "../interfaces"
import {Form} from "react-bootstrap"

const FrameContextless = (props:FormObjectFrameInterface) => {  

  return (
    <Form.Group className={`mb-3 ${props.className}`} controlId={`fi-${Math.floor(Math.random() * 500)}`}>
      <Form.Label className="form-captions d-flex justify-content-between">
        <span className="text-start">{props.caption}</span>
        <span className="text-end">{props.maxLength? `${props.maxLength - (props?.value?.length ?? 0)} character(s) remaining` : ''}</span>
      </Form.Label>
      {props.children}
    </Form.Group>
  )
}

export default FrameContextless