import React from 'react'
import { AugTable } from '../../../../../../components/Table'
import { DataManager, Query, UrlAdaptor } from '@syncfusion/ej2-data';

import {Row,Col} from "react-bootstrap"

import ContentFrame from '../../../../../../Frame/ContentFrame'
import { base_url } from '../../../../../../components/apiHandler';

const B3610Specifications = () => {
  const schedule_editor = {
    params: {
        actionComplete: () => false,
        allowFiltering: true,
        dataSource: new DataManager({
          adaptor: new UrlAdaptor(),
          url: base_url + "standards/asme/b3610m/pipe_schedules.php?action=GET_SCHEDULES"
      }),
        fields: { text: "b3610_sc_schedule", value: "b3610_sc_id" },
        query: new Query()
    } 
  }
  return (
    <ContentFrame title="ASME B36.10M Specifications (Weight / Dimensions)">
      <Row>
        <Col xs={12}>
          <AugTable
            allowResizing
            allowReordering
            allowEditing
            editOptions={{
              allowEditing: true, 
              allowAdding: true,
              showConfirmDialog: true,
              showDeleteConfirmDialog: true,
              allowDeleting: true,
              mode: "Dialog",
            }}
            columns={[
              {field : 'b3610_id',headerText : 'ID', isIdentity: true},
              {field : 'b3610_sc_schedule',headerText : 'Schedule', editType:'dropdownedit', edit: schedule_editor},
              {field : 'b3610_dn',headerText : 'DN'},
              {field : 'b3610_nps',headerText : 'NPS'},
              {field : 'b3610_od',headerText : 'Outer Diameter, mm'},
              {field : 'b3610_wt',headerText : 'Wall Thickness, mm'},
              {field : 'b3610_plain_end_mass',headerText : 'Plain End Mass kg/m'},
            ]}
            dataManagerUrl={{
              url: "standards/asme/b3610m/pipe_dimensions.php?action=INIT",
              insertUrl: "standards/asme/b3610m/pipe_dimensions.php?action=ADD",
              removeUrl: "standards/asme/b3610m/pipe_dimensions.php?action=REMOVE",
              updateUrl: "standards/asme/b3610m/pipe_dimensions.php?action=UPDATE",
            }}
        />
        </Col>
      </Row>
    </ContentFrame>
  )
}

export default B3610Specifications