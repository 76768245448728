import React, { forwardRef } from "react";
import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import {
  MenuComponent,
  MenuItemModel,
} from "@syncfusion/ej2-react-navigations";
import { useNavigate } from "react-router-dom";
import "./sidebar.css";
import { logger } from "../../components/js";

const menuItems: MenuItemModel[] = [
  {
    text: "User",
    iconCss: "fa fa-user",
    items: [
      { text: "Create User", id: "/user/create", iconCss:'fa fa-user-plus text-success' },
      { text: "Logout", id: "/user/logout", iconCss:'fa fa-sign-out text-danger' },
    ],
  },
  {
    text: "Report",
    iconCss: "fa fa-file-alt",
    items: [
      {
        text: "PSV",
        id: "/report/psv",
        items: [
          { id: "/report/psv/generate/v4", text: "Generate New", iconCss:'fas fa-plus text-success' },
          { id: "/report/psv/view", text: "View Previous Reports", iconCss :'fa fa-eye text-primary' },
        ],
      },
    ],
  },
  {
    text: "Standards",
    iconCss: "fa fa-file-alt",
    items: [
      {
        text: "Material",
        id: "/standards/material",
      },
      {
        text: "API",
        id: "/standards/api",
        items: [
          {
            id: "/standards/api/520",
            text: "520",
          },
          {
            id: "/standards/api/521",
            text: "521",
          },
          {
            id: "/standards/api/526",
            text: "526",
            items: [
              { id: "/standards/api/526/orifice", text: "Standard Orifices" },
              { id: "/standards/api/526/valve_body", text: "Valve Body" },
            ],
          },
        ],
      },
      {
        text: "ANSI",
        id: "/standards/ansi",
        items: [
          {
            text: "Flange Classes",
            id: "/standards/ansi/asme/flange",
          },
          {
            text: "B16.34",
            id: "/standards/ansi/asme/b16_34",
          },
          {
            text: "B16.36M",
            items: [
              {
                text: "Schedule",
                id: "/standards/ansi/asme/b16_36/schedule",
              },
              {
                text: "Specifications",
                id: "/standards/ansi/asme/b16_36/specs",
              },
            ],
          },
        ],
      },
    ],
  },
];

const SideBar = forwardRef((props: any, ref: any) => {
  const navigate = useNavigate();

  const select = (args: any) => {
    logger.log("Select - args", args);
    if (args.item.id.charAt(0) === "/") {
      navigate(args.item.id);
    }
  };

  return (
    <SidebarComponent
      id="menuSidebar"
      className="sidebar-menu"
      ref={ref}
      enableDock={true}
      dockSize={"50px"}
      width={"220px"}
      target={".main-menu-content"}
      isOpen={true}
      type="Auto"
    >
      <div className="main-menu h-100">
        <div className="h-100">
          <MenuComponent
            select={select}
            id="dockMenu"
            items={menuItems}
            orientation="Vertical"
            cssClass="dock-menu"
          ></MenuComponent>
        </div>
      </div>
    </SidebarComponent>
  );
});

export default SideBar;
