import React from 'react'
import { AugTable } from '../../../../../components/Table'
import ContentFrame from '../../../../../Frame/ContentFrame'

const Flange = () => {
  return (
    <ContentFrame title="Flange Classes">
      <AugTable         
        allowResizing
        allowReordering
        allowEditing
        editOptions={{
          allowEditing: true,
          allowAdding: true,
          showConfirmDialog: true,
          showDeleteConfirmDialog: true,
          allowDeleting: true,
          mode: "Dialog",
        }}
        columns={[
          {field: 'ac_id', headerText :'ID', isIdentity :true},
          {field: 'ac_class', headerText :'Class'},
        ]}
        dataManagerUrl={{
          url: "standards/asme/flange.php?action=INIT",
          insertUrl: "standards/asme/flange.php?action=ADD",
          removeUrl: "standards/asme/flange.php?action=REMOVE",
          updateUrl: "standards/asme/flange.php?action=UPDATE",
        }}
      />
    </ContentFrame>
  )
}

export default Flange