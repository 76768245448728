import { Generate } from './index';

export const temperature_converter_values = {
  C : {
    F : (x:number) => (Number(x) * 9 / 5 ) + 32,
    R : (x:number) => (Number(x) * 9 / 5 ) + 491.67,
    K : (x:number) => Number(x) + 273.15,
  },
  F : { C : (x:number) => (Number(x) - 32) * 5 / 9  },
  R : { C : (x:number) => (Number(x) - 491.67) * 5 / 9  },
  K : { C : (x:number) => Number(x) - 273.15  },
}

export type temperature_type = Generate<keyof typeof temperature_converter_values>
export const temperature_types = Object.keys(temperature_converter_values)

// export const convertTemperature = 
//   (num: number, from: keyof typeof temperature_converter_values, to: keyof typeof temperature_converter_values) => {
//   return converter(num, temperature_converter_values, "C",from,to)
// }
export const convertTemperature = (num : number, from: keyof typeof temperature_converter_values, to: keyof typeof temperature_converter_values) => {
  if (from === undefined || to === undefined) { return 0}
  const _base_val = from === "C" ? Number(num) : temperature_converter_values[from].C(num)

  return to === "C" ? _base_val : temperature_converter_values.C[to](_base_val)
}