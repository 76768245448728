import { useState, useEffect, useMemo } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { FormObjectInterface, FormValidationObject } from "../interfaces";
// import { HtmlEditor, Image, Inject, Link,Count, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
// import {stateFromHTML} from 'draft-js-import-html'
import "/node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import HtmlToDraft from "html-to-draftjs"
import { Form } from "react-bootstrap";

import Frame from "../Components/Frame";
import produce from "immer";
// import { isConstructorDeclaration } from "typescript";
// import htmlToDraft from "html-to-draftjs";

export const Line = (props: FormObjectInterface) => {
  const methods = useFormContext();
  const [lineValue, setLineValue] = useState<any>("");

  useEffect(() => {
    if (props.readOnlyValue) {
      methods.setValue(props.name, props.readOnlyValue);
    }

    // eslint-disable-next-line
  }, [props?.readOnlyValue]);

  const toWatch = props.calculatedField
    ? methods.watch(props.calculatedField.find)
    : props.calculatedFields
    ? methods.watch(props.calculatedFields.find)
    : null;

  const _rules = useMemo(() => {
    return produce({} as FormValidationObject, (draft) => {
      if (props.required) {
        draft.required = {
          value: typeof props.required === "boolean" ? props.required : true,
          message:
            typeof props.required === "boolean"
              ? "Please Add a Value for this Field"
              : props.required,
        };
      }
      if (props.max) {
        draft.max = {
          value: props.max,
          message: "Max Value Achieved!",
        };
      }
      if (props.min) {
        draft.min = {
          value: props.min,
          message: "Min Value Achieved!",
        };
      }
      if (props.maxLength) {
        draft.maxLength = {
          value: props.maxLength,
          message: "Max Length Achieved!",
        };
      }
      if (props.minLength) {
        draft.minLength = {
          value: props.minLength,
          message: "Min Length Achieved!",
        };
      }
    });
  }, [props.required, props.max, props.min, props.maxLength, props.minLength]);
  useEffect(() => {
    if (toWatch === null) {
    } else {
      // const obj = props.name.includes(".") ?
      //   createNest(props.name,props.calculatedField ? props?.calculatedField?.calculation(toWatch) : props?.calculatedFields?.calculation(toWatch)) :
      const obj = {
        name: props.name,
        value: props.calculatedField
          ? props?.calculatedField?.calculation(
              toWatch,
              methods.getValues(props.name),
              methods.getValues()
            )
          : props?.calculatedFields?.calculation(
              toWatch,
              methods.getValues(props.name),
              methods.getValues()
            ),
      };

      methods.setValue(obj.name, obj.value);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(toWatch)]);

  return (
    <Frame {...props} value={lineValue} errors={methods.formState.errors}>
      <Controller
        control={methods.control}
        name={props.name}
        rules={_rules}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          props.plainText === true ? <p className="fw-bold text-center h5">{value}</p> : <Form.Control
          ref={ref}
          className={props.className}
          style={props.style}
          disabled={props.disabled || props.readOnlyValue ? true : false}
          type={props.type}
          name={props.name}
          maxLength={props.maxLength}
          // {...props}
          placeholder={
            props.placeholder
              ? props.placeholder
              : props.caption
              ? props.caption + "..."
              : ""
          }
          onBlur={onBlur} // notify when input is touched
          onChange={(c) => {
            onChange(c);
            setLineValue(c.target.value);
          }} // send value to hook form
          value={value || ""}
        />
        )}
      />
    </Frame>
  );
};

