import React, { useState, useRef, useEffect, createContext } from "react";
import "./App.css";
import {
  ToolbarComponent,
  ItemsDirective,
  ItemDirective,
} from "@syncfusion/ej2-react-navigations";
import { Outlet } from "react-router-dom";

import Sidebar from "./Frame/Sidebar";
import LoginPage from "./content/user/login";
import { POSTRequest } from "./components/apiHandler";

export interface userObject {
  id: string;
  name: string;
  email: string;
  initials: string;
  level: number;
}

export const UserContext = createContext<{user: userObject|null, setUser: (a:userObject|null) => void}>({user:null, setUser:(a)=>{}})

function App() {
  const [user, setUser] = useState<userObject | null>(null);
  const sidebarRef = useRef<null | HTMLElement | any>(null);

  useEffect(() => {
    const _check = localStorage.getItem("synergy-login");
    if (_check !== null) {
      const _user = JSON.parse(_check)
      POSTRequest("user.php","REFRESH_LOGIN",_user).then(response => {
        setUser(_user);
      })
    }
  }, []);

  const toolbarClicked = (args: any) => {
    if (args.item.tooltipText === "Menu") {
      sidebarRef.current.toggle();
    }
  };

  let folderEle: string =
    '<div class= "e-folder"></span><div class= "e-folder-name">Navigation Pane</div></div>';

  return <UserContext.Provider value={{user : user, setUser: setUser}}>
  {user === null ? <LoginPage /> : (
    <div id="wrapper" className="control-section">
      <div id="sidebarmenu">
        {/* header-section  declaration */}
        <div>
          <ToolbarComponent id="menuToolbar" clicked={toolbarClicked}>
            <ItemsDirective>
              <ItemDirective
                prefixIcon="fa-solid fa-bars"
                tooltipText="Menu"
              ></ItemDirective>
              <ItemDirective template={folderEle}></ItemDirective>
            </ItemsDirective>
          </ToolbarComponent>
        </div>
        {/* main content declaration */}
        <div className="main-menu-content h-100" id="maintext">
          <div className="menu-content  ps-4 pt-3 h-100">
            <Outlet />
          </div>
        </div>
        {/* end of main content declaration
        sidebar element declaration */}
        <Sidebar ref={sidebarRef} />
      </div>
    </div>
  )}
  </UserContext.Provider>
}

export default App;
