import { useMemo } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { FormObjectInterface } from "../interfaces";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";

import Frame from "../Components/Frame";
import { logger } from "../../js";

export const Check = (props: FormObjectInterface) => {
  const methods = useFormContext();

  const watch = props.disableIf ? methods.watch(props.disableIf.find) : null;

  const disableButton = useMemo(() => {
    if (props.disableIf === undefined) {
      return undefined;
    }

    return props.disableIf.calculation(watch);
  }, [watch, props.disableIf]);

  return (
    <Frame {...props}>
      <Controller
        control={methods.control}
        name={props.name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <CheckBoxComponent
            disabled={disableButton}
            label={props.caption}
            onBlur={onBlur} // notify when input is touched
            change={(args: any) => {
              logger.log(args);
              onChange(args.checked);
            }} // send value to hook form
            // value={value}
            checked={value}
          />
        )}
      />
    </Frame>
  );
};
