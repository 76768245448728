import React from 'react'
import ReactDOMServer from 'react-dom/server';
import Html, { HtmlStyles } from 'react-pdf-html';
import { borderDefault, _thickBorder } from '../items';
// import { parseContentBlock } from './components/core';

interface ReactParseeProps {
  children : React.ReactElement,
  stylesheet ?: HtmlStyles | HtmlStyles[]
}

const ReactParsee = (props: ReactParseeProps) => { 
  const _styleSheets = {
    ".d-flex" : {display:'flex'},
    ".headerRow" : {display:"flex",flexDirection:"row" },
    ".pb-0" : {paddingBottom:'0'},
    ".borderBottom" : {borderBottom : borderDefault},
    ".borderRight" : {borderRight : borderDefault},
    ".borderLeft" : {borderLeft : borderDefault},
    ".borderTop" : {borderTop : borderDefault},
    ".border" : {border : borderDefault},
    ".borderBottomT" : {borderBottom : _thickBorder},
    ".borderRightT" : {borderRight : _thickBorder},
    ".borderLeftT" : {borderLeft : _thickBorder},
    ".borderTopT" : {borderTop : _thickBorder},
    ".borderT" : {border : _thickBorder},
    ".width-30" : {minWidth:"30px",maxWidth:"30px",width:"30px"},
    ".width-40" : {minWidth:"40px",maxWidth:"40px",width:"40px"},
    ".width-44" : {minWidth:"44px",maxWidth:"44px",width:"44px"},
    ".width-50" : {minWidth:"50px",maxWidth:"50px",width:"50px"},
    ".width-60" : {minWidth:"60px",maxWidth:"60px",width:"60px"},
    ".width-75" : {minWidth:"75px",maxWidth:"75px",width:"75px"},
    ".width-80" : {minWidth:"80px",maxWidth:"80px",width:"80px"},
    ".width-90" : {minWidth:"90px",maxWidth:"90px",width:"90px"},
    ".width-100" : {minWidth:"100px",maxWidth:"100px",width:"100px"},
    ".width-120" : {minWidth:"120px",maxWidth:"120px",width:"120px"},
    ".width-150" : {minWidth:"150px",maxWidth:"150px",width:"150px"},
    ".width-160" : {minWidth:"160px",maxWidth:"160px",width:"160px"},
    ".width-200" : {minWidth:"200px",maxWidth:"200px",width:"200px"},
    ".width-220" : {minWidth:"220px",maxWidth:"220px",width:"220px"},
    ".width-250" : {minWidth:"250px",maxWidth:"250px",width:"250px"},
    ".headerCol" : {display:"flex",flexDirection:"column" },  
    ".fw-bold" : {fontWeight:"bold"},
    ".text-center" : {textAlign:"center"},
    ".background-grey" : {backgroundColor:"gainsboro"},
    ".text-8" : {fontSize:'8pt'},
    ".text-9" : {fontSize:'9pt'},
    ".text-10" : {fontSize:'10pt'},
    "textwrap" : {textOverflow : "auto"},
    p: { margin: "1px 0px" },
    ...props.stylesheet
  }
  const html = ReactDOMServer.renderToStaticMarkup(props.children)

  return (
    <Html stylesheet={_styleSheets} style={{ fontSize: '10px' }}>{html}</Html>
  )
}

export default ReactParsee