import React from "react";
import { AugTable } from "../../../../../components/Table";
import ContentFrame from "../../../../../Frame/ContentFrame";

const ValveBody = () => {
  return (
    <ContentFrame title="Valve Body Specifications">
      <AugTable
        allowEditing
        editOptions={{
          allowEditing: true,
          allowAdding: true,
          showConfirmDialog: true,
          showDeleteConfirmDialog: true,
          allowDeleting: true,
          mode: "Dialog",
        }}
        columns={[
          { field: "vs_id", headerText: "ID" ,isIdentity: true},
          { field: "vs_bm_name", headerText: "Designation" },
          { field: "vs_", headerText: "Effective Area (sq in)" },
        ]}
        dataManagerUrl={{
          url: "standards/valve_body.php?action=INIT",
          insertUrl: "standards/valve_body.php?action=ADD",
          removeUrl: "standards/valve_body.php?action=REMOVE",
          updateUrl: "standards/valve_body.php?action=UPDATE",
        }}
      />
    </ContentFrame>
  );
};

export default ValveBody;
