import React, {useEffect} from "react"
import {onChangeHandler} from "../Components/utils"
import {useFormContext, Controller} from "react-hook-form" 
import { FormDatePickerInterface } from "../interfaces";
import DatePicker, { registerLocale } from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 

import {Button, Row, Col} from "react-bootstrap"
import Frame from "../Components/Frame"
import enGB from "date-fns/locale/en-GB";

export const Datepicker  = (props: FormDatePickerInterface) => {
  const methods = useFormContext();
  useEffect(() => {registerLocale('en-GB', enGB);},[])
  
  const toWatch = props.calculatedField
  ? methods.watch(props.calculatedField.find)
  : props.calculatedFields
  ? methods.watch(props.calculatedFields.find)
  : null;

  useEffect(() => {
    if (toWatch === null) {
    } else {
      // const obj = props.name.includes(".") ?
      //   createNest(props.name,props.calculatedField ? props?.calculatedField?.calculation(toWatch) : props?.calculatedFields?.calculation(toWatch)) :
      const obj = {
        name: props.name,
        value: props.calculatedField
          ? props?.calculatedField?.calculation(
              toWatch,
              methods.getValues(props.name),
              methods.getValues()
            )
          : props?.calculatedFields?.calculation(
              toWatch,
              methods.getValues(props.name),
              methods.getValues()
            ),
      };

      methods.setValue(obj.name, obj.value);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(toWatch)]);

  const createFormat = () => {
    if (props.show === undefined) {return 'yyyy-MM-dd'}

    switch(props.show){
      case 'year':
        return 'yyyy'
      case 'month':
        return 'MM/yyyy'
      default:
        return 'yyyy-MM-dd'
    }
  }

  return (    
    <Frame {...props}>
      <Controller 
        control={methods.control}
        name={props.name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <Row>
            {
              props.showLeftRight && 
              <Col xs={12} sm={2} className="text-start" onClick={()=>{onChangeHandler(new Date(value.getTime() - 86400000),onChange)}}><Button>Previous Day </Button></Col>
            }
            <Col xs={props.showLeftRight? 8 : 12} >
              <DatePicker   
                locale="en-GB"
                showYearPicker={props.show === 'year'}
                showMonthYearPicker={props.show ==='month'}
                dateFormat={createFormat()}
                selected={value} 
                onChange={(event:any)=>onChangeHandler(event,onChange)} 
                className={`form form-control ${props.className}`}              
                popperPlacement="top-end"
              />
            </Col>
            {
              props.showLeftRight && 
              <Col xs={12} sm={2} className="text-end" onClick={()=>{onChangeHandler(new Date(value.getTime() + 86400000),onChange)}}><Button>Next Day </Button></Col>
            }
          </Row>
        )}
      />
    </Frame>
  )
}
