import { useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormSelectInterface,
  FormSelectCreatableInterface,
  FormSelectOptionsInterface,
} from "../../interfaces";
import Select from "react-select";
import Creatable from "react-select/creatable";

import Frame from "../../Components/Frame";
import FrameContextless from "../../Components/FrameContextless";
import { logger } from "../../../js";
import { POSTRequest } from "../../../apiHandler";

export const SelectCreatable = (props: FormSelectCreatableInterface) => {
  const { control } = useFormContext();
  const [selValue, setSelValue] = useState<any>(
    props.valueSetting ? props.valueSetting : {}
  );
  const defaultRequired =
    props.required !== undefined
      ? typeof props.required === "string"
        ? props.required
        : props.required === true
        ? "Please select a value for this field"
        : undefined
      : undefined;
  return (
    <Frame {...props}>
      <Controller
        control={control}
        name={props.name}
        rules={{ required: defaultRequired }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <Creatable
            isClearable
            onChange={(c) => {
              logger.log(c);
              onChange(c);
              setSelValue(c);
              if (props.valueAsState) {
                props.valueAsState(c);
              }
            }}
            onCreateOption={(value) => {
              props.onCreate(value);
              if (props.internalAdd) {
                setSelValue({ value: value, label: value });
              }
            }}
            options={props.options}
            value={selValue}
          />
        )}
      />
    </Frame>
  );
};

export const SelectNoAdd = (props: FormSelectInterface) => {
  const { control, setValue } = useFormContext();
  const [selValue, setSelValue] = useState(null);
  const [options,setOptions] = useState<FormSelectOptionsInterface[]>(props.options ? [...props.options] : [])

  useEffect(()=> {
    if (props.serverSide !== undefined) {
      POSTRequest(props.serverSide.url,props.serverSide.a).then(response => setOptions(response))
    }
  //eslint-disable-next-line
  },[])
  const defaultRequired =
    props.required !== undefined
      ? typeof props.required === "string"
        ? props.required
        : props.required === true
        ? "Please select a value for this field"
        : undefined
      : undefined;
  useEffect(() => {
    if (selValue === null) {
      return;
    }
    if (props.setValue) {
      setValue(props.setValue.inputName, props.setValue.value);
    }
    // eslint-disable-next-line
  }, [props?.setValue?.value]);

  const _styles = {
    clearIndicator: (styles: any) => ({
      ...styles,
      ...props.styles?.clearIndicator,
    }),
    container: (styles: any) => ({ ...styles, ...props.styles?.container }),
    control: (styles: any) => ({ ...styles, ...props.styles?.control }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      ...props.styles?.dropdownIndicator,
    }),
    group: (styles: any) => ({ ...styles, ...props.styles?.group }),
    groupHeading: (styles: any) => ({
      ...styles,
      ...props.styles?.groupHeading,
    }),
    indicatorsContainer: (styles: any) => ({
      ...styles,
      ...props.styles?.indicatorsContainer,
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      ...props.styles?.indicatorSeparator,
    }),
    input: (styles: any) => ({ ...styles, ...props.styles?.input }),
    loadingIndicator: (styles: any) => ({
      ...styles,
      ...props.styles?.loadingIndicator,
    }),
    loadingMessage: (styles: any) => ({
      ...styles,
      ...props.styles?.loadingMessage,
    }),
    menu: (styles: any) => ({ ...styles, ...props.styles?.menu }),
    menuList: (styles: any) => ({ ...styles, ...props.styles?.menuList }),
    menuPortal: (styles: any) => ({ ...styles, ...props.styles?.menuPortal }),
    multiValue: (styles: any) => ({ ...styles, ...props.styles?.multiValue }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      ...props.styles?.multiValueLabel,
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      ...props.styles?.multiValueRemove,
    }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      ...props.styles?.noOptionsMessage,
    }),
    option: (styles: any) => ({ ...styles, ...props.styles?.option }),
    placeholder: (styles: any) => ({ ...styles, ...props.styles?.placeholder }),
    singleValue: (styles: any) => ({ ...styles, ...props.styles?.singleValue }),
    valueContainer: (styles: any) => ({
      ...styles,
      ...props.styles?.valueContainer,
    }),
  };

  return (
    <Controller
      control={control}
      name={props.name}
      rules={{ required: defaultRequired }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <Frame {...props}>
          <Select
            isClearable={props.noCancel === true ? false : true}
            isDisabled={props.disabled}
            styles={_styles}
            options={options}
            value={value}
            onChange={(c) => {
              onChange(c);
              setSelValue(c);
              if (props.valueAsState) {
                props.valueAsState(c);
              }
            }}
            isMulti={props.isMulti}
          />
        </Frame>
      )}
    />
  );
};

export const SelectNoAddContextless = (props: FormSelectInterface) => {
  const [selValue, setSelValue] = useState<any>(null);
  // useEffect(()=>{
  //   if(selValue === null) {return}
  // },[props?.setValue?.value])

  return (
    <FrameContextless {...props}>
      <Select
        isClearable
        options={props.options}
        value={selValue}
        onChange={(c) => {
          setSelValue(c);
          if (props.valueAsState) {
            props.valueAsState(c);
          }
        }}
        isMulti={props.isMulti}
      />
    </FrameContextless>
  );
};

export const SelectMulti = (props: FormSelectInterface) => {
  const { control } = useFormContext();
  const defaultRequired =
    props.required !== undefined
      ? typeof props.required === "string"
        ? props.required
        : props.required === true
        ? "Please select a value for this field"
        : undefined
      : undefined;

  return (
    <Controller
      control={control}
      name={props.name}
      rules={{ required: defaultRequired }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <Frame {...props}>
          <Select
            isClearable
            isMulti
            options={props.options}
            value={value}
            onChange={onChange}
          />
        </Frame>
      )}
    />
  );
};
