import { toast, ToastOptions } from "react-toastify"
import { POSTRequest } from "../apiHandler"
import { ProgrammaticalYesNoDialog } from "../Dialog"
import { FormSelectOptionsInterface } from "../Form/interfaces"

export const isDevelopment = (!process.env.NODE_ENV) || (process.env.NODE_ENV === 'development')

type SelectAsyncTypes = 'Project' | 'Client' | 'Orifices' | 'Valves' | 'Orifices_designation' | 'References'
export const loadOptions = (text:string|string[],  type: SelectAsyncTypes, all:boolean = false) => 
new Promise<FormSelectOptionsInterface[]>((resolve, reject) => {
  POSTRequest("options/options.php", type.toUpperCase(), {text, all})
  .then(response => resolve(response))
  .catch(err => reject(err))
})

export const createNewOption = (item: string, type: SelectAsyncTypes, extraData ?: object) => 
new Promise<FormSelectOptionsInterface>((resolve, reject) => {    
  const confirmOption = (confirm: boolean) => {
    if (confirm) {
      // Data object
      const data = { type : type.toUpperCase(), item : item.trim(), ...extraData}
      alert(`${type} Added`)
      POSTRequest("options/addOptions.php","ADD",data)
      .then(response => resolve(response))
      confirmationDialog.hide()
    } else {
      alert(`${type} Not Added`)
      confirmationDialog.hide()
      reject(false)
    }
  }
  const confirmationDialog = ProgrammaticalYesNoDialog(`Create New ${type} Listing : ${item}`, confirmOption)
})

export const showAlert = (message: string, type:'info'|'success'|'warn'|'error'|'def' = 'def',options ?:ToastOptions) => {
  switch (type) {
    case 'info':
      toast.info(message,options)
      break;
    case 'success':
      toast.success(message,options)
      break;
    case 'warn':
      toast.warn(message,options)
      break;
    case 'error':
      toast.error(message,options)
      break;
    default:
      toast(message,options)
      break;
  }
}

export class Logger {
  enabled = false
  log = (x : any, ...args:any[]) => {
    this.enabled && console.log(x, ...args) 
  }
  group = (x:string, ...args:any[]) => {
    this.enabled && console.group(x, ...args)
  }
  groupCollapsed = (x:string, ...args:any[]) => {
    this.enabled && console.groupCollapsed(x, ...args)
  }
  groupEnd = (x?:string, ...args:any[]) => {
    if (x) {logger.log(x, ...args)}
    this.enabled && console.groupEnd()
  }
}

export const logger = new Logger()