import React from 'react'
import { GeneratePSVFormV4ReturnObject } from '../../..'
import { ReferencesFormItems } from '../../Tabs/References'
import ReactParser from '../core/PageParser/ReactParser'
import ContentTemplate from '../core/PageTemplate/ContentTemplate'

const References = (data: GeneratePSVFormV4ReturnObject) => {
  const prepareReference = (ref : ReferencesFormItems) => {
    const year = ref.year ?? ''
    const preparedYear = year.length > 4 ? new Date(year).getFullYear() : year
    const author = ref.author ?? ''

    return <span><i>{author}</i> {`${preparedYear} ${preparedYear || author ? ' - ':''} ${ref.ref.label}`}</span>
  }

  return <ContentTemplate title="References" index={11}>    
    <ReactParser>
      <ol>
        {data.references.map((ref,index) => <li key={`ref-key-${index}`}>{prepareReference(ref)}</li>)}
      </ol>
    </ReactParser>
  </ContentTemplate>
}
export default References