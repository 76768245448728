import { logger } from "../../../../components/js"
import { C, F2, Kb, Kc, Kd } from "./constants"

export const calculate_orifice_area = (
  type: 'critical' | 'subcritical',  
  siBackPressure:number, 
  siRelievingPressure : number, 
  siFlow:number, 
  siTemperature:number, 
  compressibility:number, 
  molecular_weight:number, 
  valve_type: string, 
  kValue:number, 
  hasPRV:boolean, 
  hasRuptureDisk: boolean 
) => {
  logger.group(`Area Calculation - ${type}`)
  const _siBackPressure = Number(siBackPressure)
  const _siFlow = Number(siFlow)
  const _siRelievingPressure = Number(siRelievingPressure)
  const _siTemperature = Number(siTemperature)
  const _compressibility = Number(compressibility)
  const _molecular_weight = Number(molecular_weight)
  const constants = {
    c : C(kValue),
    kb : Kb(valve_type === "Balanced Bellows"),
    kc : Kc(hasRuptureDisk),
    kd : Kd(hasPRV),
  }  
  
  logger.log(`Backpressure : ${siBackPressure}
   Relieving Pressure : ${_siRelievingPressure}
   Back Pressure : ${_siBackPressure}
   Flow : ${siFlow}
   Temperature : ${_siTemperature}
   Molecular Weight :${_molecular_weight}
   Valve Type : ${valve_type}
   ---CONSTANTS----------------------
   K : ${kValue}
   Compressibility : ${_compressibility}
   C:  ${constants.c}
   kB : ${constants.kb}
   kC : ${constants.kc}
   kD : ${constants.kd}
  `)
  logger.groupEnd()
  if (type === 'critical') {
    const firstPart = _siFlow / (constants.c * constants.kb * constants.kc * constants.kd * _siRelievingPressure) 
    const TZOverM = _siTemperature * _compressibility / _molecular_weight
    const SqrtTZOverM = Math.sqrt(TZOverM)
    logger.log(`Critical Flow Area Calculation : W/(cKdP1KbKc) = ${firstPart}, TZ/M = ${TZOverM}, Sqrt(TZ/M) = ${SqrtTZOverM}`)
    return firstPart * SqrtTZOverM
  } else {
    const constants_f2 = F2(kValue,_siBackPressure,_siRelievingPressure)
    const firstPart = (17.9 * _siFlow) 
    const constantsF2xkdxkc = (constants_f2 * constants.kd * constants.kc) 
    const pressure_delta = _siRelievingPressure - _siBackPressure
    const rightDivisor = _molecular_weight * _siRelievingPressure * pressure_delta
    logger.log(`17.9 x flow = ${firstPart}, F2 x kc x kd = ${constantsF2xkdxkc}, pressure Delta - ${pressure_delta}, M*P*(p1-p2) = ${rightDivisor}`)
    logger.log(`F2 : ${constants_f2}, Flow : ${_siFlow}, Kd:  ${constants.kd}, kc : ${constants.kc}, Temperature : ${_siTemperature}, compressibility : ${_compressibility} `,)
    return ( firstPart/constantsF2xkdxkc ) * Math.sqrt( _siTemperature * _compressibility / rightDivisor)
  }

} 