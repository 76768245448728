import { View } from '@react-pdf/renderer'
import React from 'react'
import { SectionHeader } from './SectionHeader'

interface ContentTemplateProps {
  children : any,
  title : string, 
  index : number,
  titleStyles ?: object, 
  break ?:boolean,
  debug ?:boolean
}
const ContentTemplate = (props:ContentTemplateProps) => {
  return (
  <View wrap={false} break={props.break} debug={props.debug} style={{width: "100%",display:'flex',flexDirection:'column', fontSize:'12px', minHeight:'15px', paddingBottom:'15px'}}>
    <SectionHeader headerStyle={props.titleStyles} title={props.title} index={props.index}/>
    <View style={{}}>{props.children}</View>
  </View>
  )
}

export default ContentTemplate