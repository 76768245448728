import { View, Text } from "@react-pdf/renderer";
import { logger } from "../../../../../../../components/js";
import { AppendixTabFormItems } from "../../Tabs/AppendixTab";

export const _thickBorder = "2px solid black";
export const borderDefault = "1px solid black";

export const pdfRow = (content: Function = () => {}, styles = {}) => (
  <View
    style={{
      flexGrow: 1,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ...styles,
    }}
  >
    {content()}
  </View>
);

export const pdfDetailRow = (
  name: string,
  value: string,
  delimiter: string = ":",
  nameStyles = {},
  delimiterStyles = {},
  valueStyles = {},
  boxStyles = {}
) => {
  logger.groupCollapsed("PDF Detail Row");
  logger.log("[BOX STYLES] - ", boxStyles);
  logger.log("[Complete] - ", {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingRight: "20px",
    paddingLeft: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    ...boxStyles,
  });
  logger.groupEnd();
  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        paddingRight: "20px",
        paddingLeft: "20px",
        paddingTop: "10px",
        paddingBottom: "10px",
        ...boxStyles,
      }}
    >
      <View>
        <Text style={{ textAlign: "left", width: "200px", ...nameStyles }}>
          {name}
        </Text>
      </View>
      <View>
        <Text style={{ width: "50px", ...delimiterStyles }}>{delimiter}</Text>
      </View>
      <View>
        <Text
          style={{
            textAlign: "left",
            width: 200,
            color: "#2e19e6",
            ...valueStyles,
          }}
        >
          {value}
        </Text>
      </View>
    </View>
  );
};

export const pdfDetailRowForPageNum = (
  name: string,
  value: string,
  delimiter: string = ":",
  nameStyles = {},
  delimiterStyles = {},
  valueStyles = {},
  boxStyles = {}
) => {
  logger.groupCollapsed("PDF Detail Row (Page NUm)");
  logger.log("[BOX STYLES] - ", boxStyles);
  logger.log("[Complete] - ", {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingRight: "20px",
    paddingLeft: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    ...boxStyles,
  });
  logger.groupEnd();
  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        paddingRight: "20px",
        paddingLeft: "20px",
        paddingTop: "10px",
        paddingBottom: "10px",
        ...boxStyles,
      }}
    >
      <View>
        <Text style={{ textAlign: "left", width: "200px", ...nameStyles }}>
          {name}
        </Text>
      </View>
      <View>
        <Text style={{ width: "50px", ...delimiterStyles }}>{delimiter}</Text>
      </View>
      <View>
        <Text
          style={{
            textAlign: "left",
            width: 200,
            color: "#2e19e6",
            ...valueStyles,
          }}
          render={({ subPageNumber, subPageTotalPages }) =>
            `Sheet ${subPageNumber} of ${subPageTotalPages}`
          }
        />
      </View>
    </View>
  );
};

export const pdfBoxWithHeader = (
  title: string,
  titleStyles = {},
  content: Function = () => {},
  contentStyles = {},
  boxStyles = {}
) => (
  <View
    style={{
      border: "1px solid black",
      display: "flex",
      flexDirection: "column",
      ...boxStyles,
    }}
  >
    <View style={{ width: "100%", ...titleStyles }}>
      <Text>{title}</Text>
    </View>
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        borderTop: "1px solid black",
        ...contentStyles,
      }}
    >
      <Text>{content()}</Text>
    </View>
  </View>
);

export const toc = (appendix: AppendixTabFormItems[]) => {
  const tocItems = {
    main: [
      "Objectives",
      "PSV Data",
      "Potential Overpressure Scenarios",
      "Calculation Basis and Assumptions",
      "Fluid Composition",
      "Control Valve Data",
      "Equipment Data",
      "Results",
      "Conclusions",
      "Recommendations",
      "Reference",
      "Additional Notes",
    ],
    appendix: appendix.map((_appendix) => _appendix.name),
  };

  const _boxStyles = {
    paddingTop: "3px",
    paddingBottom: "3px",
    marginTop: "1px",
    fontSize: "8px",
    color: "black",
    justifyContent: "flexStart",
    alignItems: "flexStart",
  };
  const _nameStyles = { width: "50px", textAlign: "right" };
  const _delimiterStyles = { width: "25px" };
  const _valueStyles = { color: "black", textAlign: "left", width: "200px" };

  return (
    <View
      style={{
        marginTop: "20px",
        width: "75%",
        border: "1px solid black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          borderBottom: "1px solid black",
          fontSize: "9px",
          marginBottom: "10px",
        }}
      >
        <Text>TABLE OF CONTENTS</Text>
      </View>
      {pdfDetailRow(
        "1.0",
        tocItems.main[0],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "2.0",
        tocItems.main[1],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "3.0",
        tocItems.main[2],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "4.0",
        tocItems.main[3],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "5.0",
        tocItems.main[4],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "6.0",
        tocItems.main[5],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "7.0",
        tocItems.main[6],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "8.0",
        tocItems.main[7],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "9.0",
        tocItems.main[8],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "10.0",
        tocItems.main[9],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "11.0",
        tocItems.main[10],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "12.0",
        tocItems.main[11],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "Appendix",
        "",
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "I",
        tocItems.appendix[0],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "II",
        tocItems.appendix[1],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "III",
        tocItems.appendix[2],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "IV",
        tocItems.appendix[3],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "V",
        tocItems.appendix[4],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
      {pdfDetailRow(
        "VI",
        tocItems.appendix[5],
        "  ",
        _nameStyles,
        _delimiterStyles,
        _valueStyles,
        _boxStyles
      )}
    </View>
  );
};
