import { Page, View, Text } from '@react-pdf/renderer'
import React from 'react'
import ReactParser from "./core/PageParser/ReactParser";
import { GeneratePSVFormV4ReturnObject } from '../..'
import { pdfDetailRow } from './core/items'
import { AppendixTabFormItems } from '../Tabs/AppendixTab';

// const tocItems = {  
//   main : ['Objectives','PSV Data','Potential Overpressure Scenarios','Calculation Basis and Assumptions','Fluid Composition','Control Valve Data','Equipment Data','Results','Conclusions','Recommendations','Reference','Additional Notes'],
//   appendix : ['Relief Valve Sizing Calculation Sheets','PSV Inlet Line Sizing Adequacy Check Spreadsheet','PSV Outlet Line Sizing Adequacy Check Spreadsheet','Relevant P&ID','Relevant Datasheet','Relevant Isometrics']
// }
const mainTOC = [
  "Objectives",
  "PSV Data",
  "Potential Overpressure Scenarios",
  "Calculation Basis and Assumptions",
  "Fluid Composition",
  "Control Valve Data",
  "Equipment Data",
  "Results",
  "Conclusions",
  "Recommendations",
  "Reference",
  "Additional Notes",
]

const romanize = (num:number) => {
  if (isNaN(num)) {return NaN};
  var digits = String(+num).split(""),
      key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
             "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
             "","I","II","III","IV","V","VI","VII","VIII","IX"],
      roman = "",
      i = 3;
  while (i--)
      roman = (key[+(digits?.pop() ?? '') + (i * 10)] || "") + roman;
  return Array(+digits.join("") + 1).join("M") + roman;
}

const toc = (appendix : AppendixTabFormItems[]) => {
  return <div style={{marginTop:'10px', width:'450px'}} className="border">
    <div className="fw-bold text-center borderBottom" style={{marginBottom:'20px'}}>TABLE OF CONTENTS</div>
    {mainTOC.map((toc,index:number) => <div className="headerRow" key={`toc-main-${index}`}><div style={{marginBottom:'8px'}} className="width-90">{(index + 1).toFixed(1)}</div><div>{toc}</div></div>)}
    <div className="fw-bold" style={{textAlign:'left',marginBottom:'10px', paddingLeft:'10px'}}>APPENDIX</div>
    {appendix.map((app,index:number) => <div className="headerRow" key={`toc-app-${index}`}><div style={{marginBottom:'8px'}} className="width-90">{romanize(index + 1)}</div><div>{app.name}</div></div>)}
  </div>
}

export const pdfFrontPage = (data : GeneratePSVFormV4ReturnObject) => (
  <Page size="A4" style={{width: '100%', display: 'flex',flexDirection: 'column',justifyContent: 'center'}}>
    {/* pdfRow() */}
    <View style={{flexGrow:1, textAlign: 'center', display: 'flex', alignItems:'center', justifyContent:'center'}}>
      <View style={{justifyContent:'center', padding:'10px', width:'75%',border:'1px solid black', fontWeight:'heavy'}}>                
        <Text>RELIEF VALVE SIZING CALCULATION REPORT</Text>
      </View>
      <View style={{marginTop:'20px',paddingTop:'20px',paddingBottom:'20px', fontSize:'14px', width:'75%', border:'1px solid black', display: 'flex',flexDirection: 'column',justifyContent: 'center'}}>
        {pdfDetailRow("RELIEF VALVE TAG NO",data.basic.psv_tag,":")}
        {pdfDetailRow("LOCATION",data.basic.location,":")}
      </View>
      <ReactParser>        
        {toc(data.appendix)}
      </ReactParser>
    </View>
  </Page>
)

// export default pdfFrontPage