import React from 'react'
import {Row,Col} from "react-bootstrap";

interface ContentFrameType {
  title : string, 
  children : React.ReactNode
}

const ContentFrame = (props:ContentFrameType) => {
  return (
    <Row>
      <Col xs={12}><p className="h4 fw-bold mb-3">{props.title}</p></Col>
      <Col xs={12}>
        {props.children}
      </Col>
    </Row>
  )
}

export default ContentFrame